import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../utils/CommonUtils";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import TableLoader from "@components/Loaders/tableLoader";
import {Table, Button, Space, Tooltip, Pagination, Typography} from "antd";
import PaginationLoader from "@components/Loaders/paginationLoader";
import HeaderTamplate from "@components/HeaderTamplate";
import OperatorInfo from "@components/OperatorsInfo";
import {setPagination} from "@actions/paginationsActions";
import {useHistory} from "react-router-dom";
import {
    getOperarorApiKays,
    deleteOperarorApiKay
} from "@actions/operatorApiKaysActions";
import { DeleteFilled, EditFilled, CopyFilled, PlusOutlined } from '@ant-design/icons';
import AddEditApiAccessPopup from "../AddEditApiAccessPopup";
import DeleteAPIAccessConfirmPopup from "../DeleteAPIAccessConfirmPopup";

const { Paragraph, Text } = Typography;



function ManageOperators({intl, loading, pagination, user, operatorApiKeys, setPagination, getOperarorApiKays, deleteOperarorApiKay, setDoneMsg, setErrorMsg}) {

    let history = useHistory()
    const search = history && history.location && history.location.search

    const [editApiKey, setEditApiKey] = useState({});
    const [apiKeyPopupMode, setApiKeyPopupMode] = useState("");
    const [selectedOperatorId, setSelectedOperatorId] = useState(getUrlValue(search).operatorId);
    const [confirmDeleteId, setConfirmDeleteId] = useState();

    

    const columnsApiKeys = [
        {
            title: intl.formatMessage({id: "api_key_name"}),
            dataIndex: 'name',
            className: "copyable-column",
            render: (name, record) => {
                return <span>
                        <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                            {name}
                        </Text>
                        <span className="copyable" onClick={(e) => {
                                    copyLabel(e, name, setDoneMsg, setErrorMsg);
                                }}>
                            <CopyFilled />
                        </span>
                </span>
            }
        },
        {
            title: intl.formatMessage({id: "auth_callback_url"}),
            dataIndex: 'authCallbackUrl',
            className: "copyable-column",
            render: (authCallbackUrl, record) => {
                return <span>
                        <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                            {authCallbackUrl}
                        </Text>
                        <span className="copyable" onClick={(e) => {
                            copyLabel(e, authCallbackUrl, setDoneMsg, setErrorMsg);
                        }}>
                            <CopyFilled />
                        </span>
                </span>
            }
        },
        {
            title: intl.formatMessage({id: "api_key"}),
            dataIndex: 'apiSecret',
            className: "copyable-column",
            render: (id, record) => {
                return <span>
                        <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                            {id}
                        </Text>
                        <span className="copyable" onClick={(e) => {
                                    copyLabel(e, id, setDoneMsg, setErrorMsg);
                                }}>
                            <CopyFilled />
                        </span>
                </span>
            }
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'id',
            width: 100,
            render: (id, record) => {
                return <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                    <Tooltip placement="top" title={<FormattedMessage id="edit"/>}>
                        <EditFilled onClick={() => {
                            setEditApiKey({...record, currencyId: record.currency?.id || '', allowedCountries: record.allowedCountries?.map(x => x.id) || []}) ;
                            setApiKeyPopupMode("edit");
                        }}/>
                    </Tooltip>
                    <span className="line-vertical"></span>
                    <Tooltip placement="top" title={<FormattedMessage id="delete"/>}>
                        <DeleteFilled onClick={() => onDelete(record)}/>
                    </Tooltip>
                </Space> 
            }
        }
    ];

    const onDelete = (item) => {
        setConfirmDeleteId(item.id);
    }

    const onDeleteAPIAccess = (id) => {
        deleteOperarorApiKay(id);
        fetchOperarorApiEvents();
    }


    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchOperarorApiEvents(true)

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])
 

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }


    const fetchOperarorApiEvents = (isFirst) => {
        let initialForm = getApiEventsRequestBody();
        initialForm = {
            ...initialForm,
            tab: 1,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getApiEventsRequestBody(loc)
                if (initialForm.tab != 1) return;
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: Math.max(10, (+initialForm.limit || pagination.limit)),
                });
                getOperarorApiKays(initialForm);
                setSelectedOperatorId(initialForm.operatorId);
            }
        })
    },[history])

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const initialForm = getApiEventsRequestBody()

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit, tab: 1})
        history.push({search: params.toString()})

    }

    const onChangeOperator = (operator) => {
        setSelectedOperatorId(operator.id);
        const initialForm = getApiEventsRequestBody()
        const {params} = getUrlString({...initialForm, operatorId: operator.id, page: initialForm.operatorId != operator.id? 1 : initialForm.page, tab: 1})
        history.push({search: params.toString()})
    }

    useEffect(() => {
        const showingPadeEnd = pagination.page * pagination.limit
        const diff = showingPadeEnd - pagination.total;
        if (diff >= pagination.limit && pagination.total > 0) {
            handleTablePaginate(Math.max(pagination.page - 1, 1), pagination.limit);
        }
    }, [pagination.total])

    return (
        <>
        <HeaderTamplate className='operator-api-keys-content manager'
            title="manage_operators"
            direction="vertical"
            lastUpdate={''}
            size={24}>
            <div className="content-template mainTable">
                <OperatorInfo onChange={operator => onChangeOperator(operator)} selectedUserId={selectedOperatorId || getUrlValue(search).operatorId}/>
            {
                
                loading._operator_api_keys === undefined || loading._operator_api_keys ?
                <TableLoader count={13} column={3} actions={1} drag={false} icon={false} pagination={false}/> :
                <Table
                    dataSource={operatorApiKeys}
                    columns={columnsApiKeys}
                    rowKey="id"
                    pagination={false}
                    />
            }

            </div>
        </HeaderTamplate>
        <Space direction="horizontal" className="bottom-bar">
            <Button className="bottom-btn" onClick={() => {
                setApiKeyPopupMode("add");
            }}>
                <PlusOutlined />
                <FormattedMessage id='add_new_api_access'/>
            </Button>
            {
                loading._operator_api_keys === undefined || loading._operator_api_keys ?
                <PaginationLoader /> :
                <Pagination defaultCurrent={pagination.page}
                    className="ant-table-pagination ant-table-pagination-right"
                    total={pagination.total} 
                    showSizeChanger={true}
                    showLessItems={true}
                    loading={loading._operator_api_keys === undefined || loading._operator_api_keys}
                    onChange={(page, limit) => handleTablePaginate(page, limit)}
                    showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                    defaultPageSize={pagination.limit}/>
            }
                        
        </Space>
        {
            apiKeyPopupMode? <AddEditApiAccessPopup handleCancel={
                () => {
                    setEditApiKey({})
                    setApiKeyPopupMode("")
                }
            }
            operatorId={selectedOperatorId}
            show={apiKeyPopupMode}
            apiKey={editApiKey}/> : ""
        }
        <DeleteAPIAccessConfirmPopup onConfirm={() => {
                                        onDeleteAPIAccess(confirmDeleteId);
                                        setConfirmDeleteId(null);
                                    }}
                                    onCancel={() => setConfirmDeleteId(null)}
                                    loading={loading["_delete_operator_api_key" + confirmDeleteId]}
                                    show={confirmDeleteId} />
        </>
    )
}


function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        pagination: state.pagination,
        operatorApiKeys: state.operatorApiKeys
    }
}

const mapDispatchToProps = {
    setPagination,
    getOperarorApiKays,
    deleteOperarorApiKay,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageOperators));