import React from "react";
import {Table, Skeleton, Space} from "antd";
import { FormattedMessage } from "react-intl";

const gamesLoader = (props) => {

    return (
        <div {...props} className="gameLoader game-item">
            <Space direction="horizontal" size={[24, 0]}>
                <Skeleton.Button active style={{width: 100, height: 100}}  paragraph={false} />
                <Space direction="vertical" >
                    <Skeleton.Button active  paragraph={false} />
                    <Space direction="horizontal" >
                        <FormattedMessage id="url" />
                        <Skeleton.Button active  paragraph={false} />
                    </Space>
                </Space>
            </Space>
            
        </div>  
    )
}

export default gamesLoader