import React, { useState } from "react";
import {FormattedMessage} from "react-intl";
import {CopyFilled, CloseOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import moment from "moment-timezone";

export default function RoundReportDetails({event, onClose}) {

    const { Paragraph } = Typography;
    const imgPath = 'assets/img'

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const playerCards = event.playerCards;
    const dealerCards = event.dealerCards;
    
    return (
        <>
        
        <div className="round-details">
            <div>
                <span>
                    <FormattedMessage id="game_id"/>:
                </span>
                <span>
                    {/* <Paragraph copyable={{
                                icon: [<CopyFilled />]
                            }}>
                        
                    </Paragraph> */}
                    {event.gameId}
                </span>
            </div>
            <div className="top-bottom-border">
                <span>
                    <FormattedMessage id="round_id"/>:
                </span>
                <span className="user-select">
                    {event.id}
                </span>
            </div>
            <div>
                <span>
                    External Round Id:
                </span>
                <span className="user-select">
                    {event.externalRoundId}
                </span>
            </div>
            <div >
                <span>
                    Start Date:
                </span>
                <span>
                    {moment(event.startDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}
                </span>
            </div>
            <div >
                <span>
                    End Date:
                </span>
                <span>
                    {moment(event.endDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}
                </span>
            </div>

        </div>
        
        {(event.gameId == "teen-patti-one-day" || event.gameId == "teen-patti-20-20" || event.gameId == "teen-patti-one-day-2" || event.gameId == "teen-patti-20-20-2") && 
        <div className="card-section">
            <div className="cards-container">
                <h2>Player A</h2>
                {playerCards && !!playerCards.length && (
                    <div>
                    {playerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 1 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`}/>
                </div>
                )}
            </div>
            <div className="cards-container">
                <h2>Player B</h2>
                {dealerCards && !!dealerCards.length && (
                    <div>
                    {dealerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 2 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`} />
                </div>
                )}
            </div>
        </div>
        }

        {(event.gameId == "dragontiger" || event.gameId == "dragontiger-2") && 
        <div className="card-section">
            <div className="cards-container">
                <h2>Dragon</h2>
                {playerCards && !!playerCards.length && (
                    <div>
                    {playerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 1 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`}/>
                </div>
                )}
            </div>
            <div className="cards-container">
                <h2>Tiger</h2>
                {dealerCards && !!dealerCards.length && (
                    <div>
                    {dealerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 2 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`} />
                </div>
                )}
            </div>
        </div>
        }

        {(event.gameId == "baccarat" || event.gameId == "baccarat-b" || event.gameId == "baccarat-2" || event.gameId == "baccarat-b-2") && 
        <div className="card-section baccarat-cards">
            <div className="cards-container">
                <h2>Player</h2>
                {playerCards && !!playerCards.length && (
                    <div>
                    {playerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 1 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`}/>
                </div>
                )}
            </div>
            <div className="cards-container">
                <h2>Banker</h2>
                {dealerCards && !!dealerCards.length && (
                    <div>
                    {dealerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}

                {event.winner && event.winner == 2 && (
                <div className="winner">
                    <img src={`${imgPath}/winner.png`} />
                </div>
                )}
            </div>
        </div>
        }

        {(event.gameId == "lucky-7" || event.gameId == "lucky-7-2") && 
        <div className="card-section">
            <div className="cards-container">
               
                {playerCards && !!playerCards.length && (
                    <div>
                    {playerCards.map((card, key) => (
                    <img src={`${imgPath}/cards/${card.barcode}.png`} key={key} className="c-img" />
                    ))}
                    </div>
                )}
            </div>
           
        </div>
        }

        <CloseOutlined className="close-button" onClick={onClose}/>
        </>
    )
}