import initialStore from "../initialStore";
import {
    SET_PLAYERS_REPORT
} from "@actions/actionTypes";

export default function playersReportReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {

        case SET_PLAYERS_REPORT:
            return action.playersReport;
        default:
            return state;
    }
}
