import React from "react";
import { Space, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
    EditFilled,
    DeleteFilled
} from "@ant-design/icons";
import gameEmptyImg from "@assets/img/icon/gameEmptyImg.svg";
const {Text} = Typography;

function GameItem({item, isEditable, onEdit, onDelete}) {

    return (
        <Space direction="horizontal" className="game-item" size={[24, 0]}>
            <img src={item.image || gameEmptyImg}/>
            <Space direction="vertical">
                <Text ellipsis className="game-name">{item.name}</Text>
                <Text ellipsis className="game-url"><FormattedMessage id="url" />: <a href={item.frontUrl}>{item.frontUrl}</a></Text>
            </Space>
            {
                isEditable? 
                <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                    <Tooltip placement="top" title={<FormattedMessage id="edit"/>}>
                        <EditFilled onClick={() => onEdit(item)}/>
                    </Tooltip>
                    <span className="line-vertical"></span>
                    <Tooltip placement="top" title={<FormattedMessage id="delete"/>}>
                        <DeleteFilled onClick={() => onDelete(item)}/>
                    </Tooltip>
                </Space> : ''
            }
            
        </Space>
    )
}

export default GameItem;