import {all, call} from 'redux-saga/effects';
import errorSaga from "./errorSaga";
import operatorApiEventsSaga from "./operatorApiEventsSaga";
import gamesSaga from "./gameSaga";
import operatorsSaga from "./operatorsSaga";
import globalApiSaga from "./globalApiSaga";
import transactionsSaga from "./transactionsSaga";
import gamesAssignmentSaga from "./gamesAssignmentSaga";
import categoriesSaga from "./categoriesSaga";
import dealersSaga from "./dealersSaga";
import gamesReportSaga from "./gamesReportSaga";
import playersReportSaga from "./playersReportSaga";
import roundReportSaga from "./roundReportSaga";

export default function* rootSaga() {
    yield all([
        call(errorSaga),
        call(operatorApiEventsSaga),
        call(gamesSaga),
        call(operatorsSaga),
        call(gamesAssignmentSaga),
        call(globalApiSaga),
        call(transactionsSaga),
        call(categoriesSaga),
        call(dealersSaga),
        call(gamesReportSaga),
        call(playersReportSaga),
        call(roundReportSaga)
    ])
}