import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_DEALERS,
    REQUEST_ADD_DEALER,
    REQUEST_EDIT_DEALER,
    REQUEST_DELETE_DEALER,
    SET_DEALERS,
    SET_ADD_DEALER,
    SET_EDIT_DEALER,
    SET_DELETE_DEALER,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource, DeleteResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* fetchDealers() {
    const dealers =  yield call(GetResource, `/Dealer`);
    yield put({type: SET_DEALERS, dealers: dealers.data});
}

function* addDealer({data}) {
    const category =  yield call(PostResource, '/Dealer', data);
    yield put({type: SET_ADD_DEALER, category: category});
    yield put({
        type: REQUEST_DEALERS
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editDealer({data}) {
    const category =  yield call(PutResource, `/Dealer/${data.id}`, data);
    yield put({type: SET_EDIT_DEALER, data: category});
    yield put({
        type: REQUEST_DEALERS
    })
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* deleteDealer({data}) {
    yield call(DeleteResource, `/Dealer/${data.id}`);
    yield put({type: SET_DELETE_DEALER, data});
    yield put({
        type: REQUEST_DEALERS
    })
}

function* dealersSaga() {
    yield takeLatest(REQUEST_DEALERS, safe(onError, fetchDealers));
    yield takeLatest(REQUEST_ADD_DEALER, safe(onError, addDealer));
    yield takeLatest(REQUEST_EDIT_DEALER, safe(onError, editDealer));
    yield takeLatest(REQUEST_DELETE_DEALER, safe(onError, deleteDealer));
}

export default dealersSaga;