import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import { useEffect } from "react";
import {
    getUsers
} from "@actions/usersActions";

const { Option } = Select;

function OperatorsSelect({users, intl, getUsers, value, onChange, loading, selectedUserId, ...anyProps}) {

    useEffect(() => {
        getUsers();
    }, [])
    
    return (
        <Select
            dropdownClassName="operator-dropdown"
            showSearch
            placeholder={intl.formatMessage({id: "select_operator"})}
            optionFilterProp="children"
            value={value}
            {...anyProps}
            onChange={onChange}
            allowClear
            loading={loading._users}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                users.map(user => (<Option key={user.id} value={user.id}>{user.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        users: state.users
    }
}

const mapDispatchToProps = {
    getUsers
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatorsSelect));