import React from "react";
import {Table, Skeleton} from "antd";

const PaginationLoader = () => {

    return (
        <div className="tableFooterLoader">
            <Skeleton.Button className="pageArrow" active  paragraph={false} />
            <Skeleton.Button className="pageItem" active  paragraph={false} />
            <Skeleton.Button className="pageItemBig" active  paragraph={false} />
            <Skeleton.Button className="pageItemBig" active  paragraph={false} />
            <Skeleton.Button className="pageItem" active  paragraph={false} />
            <Skeleton.Button className="pageItem" active  paragraph={false} />
            <Skeleton.Button className="pageItemBig" active  paragraph={false} />
            <Skeleton.Button className="pageItem" active  paragraph={false} />
            <Skeleton.Button className="pageArrow" active  paragraph={false} />
            <Skeleton.Button className="pageText" active  paragraph={false} />
            <Skeleton.Button className="pageDescription" active  paragraph={false} />
            <Skeleton.Button className="pageOf" active  paragraph={false} />
            <Skeleton.Button className="pageDescription" active  paragraph={false} />
        </div>  
    )
}

export default PaginationLoader