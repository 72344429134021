import initialStore from "../initialStore";
import {
    SET_GAMES_REPORT
} from "@actions/actionTypes";

export default function gamesReportReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {

        case SET_GAMES_REPORT:
            return action.gamesReport || null;
        default:
            return state;
    }
}
