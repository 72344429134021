import {
    REQUEST_GAMES_REPORT
} from "@actions/actionTypes";
import moment from "moment-timezone";


export function getGamesReport(data) {
    return dispatch => {
        if (data.dateRange) {
            data.Range = {};
        }
        data.dateRange?.start && (data.Range.start = moment(data.dateRange.start).unix());
        data.dateRange?.end && (data.Range.end = moment(data.dateRange.end).unix());
        delete data.dateRange;
        dispatch({type: REQUEST_GAMES_REPORT, data});
    }
}
