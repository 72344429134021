import React, { useState } from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import {Space, Form, Button, DatePicker, Select, Input} from "antd";
import UsersList from "@components/UsersList";
import classNames from "classnames";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import {EventType, EventTypeArr} from '../../constants/Enums';
import {getOperarorApiEvents} from "@actions/dashboardActions";
import {setPagination} from "@actions/paginationsActions";
import {getUrlValue, getFormattedUrlObject, getUrlString} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import moment from "moment-timezone";
import { useEffect } from "react";


function OperatorApiEventsFilter({setPagination, loading, intl, getOperarorApiEvents, user, pagination}) {

    let history = useHistory()
    const searchValue = history && history.location && history.location.search

    const [search, setSearch] = useState(getUrlValue(searchValue))
    const [form] = Form.useForm();
    const [selectedOperator, setSelectedOperator] = useState(getUrlValue(searchValue).operatorId);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchOperarorApiEvents(true);

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])


    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }


    const fetchOperarorApiEvents = (isFirst) => {
        let initialForm = getApiEventsRequestBody();
        initialForm = {
            ...initialForm,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            if (!(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)) initialForm.operatorId = user.sub;
            // getOperarorApiEvents(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location, action) => {
            if (location.pathname != "/dashboard") return;
            const loc = location && location.search
            let initialForm = getUrlValue(loc);
            initialForm = {
                ...initialForm,
                page : Math.max(1, +initialForm.page || pagination.page),
                limit: Math.max(10, +initialForm.limit || pagination.limit)
            }
            setPagination({
                page: initialForm.page,
                limit: initialForm.limit,
            })

            getOperarorApiEvents({...initialForm});

            if (initialForm.createdDateStart || initialForm.createdDateEnd) {
                initialForm.createdDate = [moment(initialForm["createdDateStart"]) || "", moment(initialForm["createdDateEnd"]) || ""]
            }

            delete initialForm.createdDateStart;
            delete initialForm.createdDateEnd;

            // form.resetFields();
            if (action === "POP") {
                setSelectedOperator(initialForm.operatorId);
            }
            let data = {...initialForm};
            form.setFieldsValue(data);
        })
    },[history])


    const selectUser = (operator) => {
        setSelectedOperator(operator?.id);
        const searchValue = history && history.location && history.location.search
        let initialForm = getApiEventsRequestBody(searchValue);

        setPagination({
            page: 1,
            limit: initialForm.limit,
        })

        const {params} = getUrlString({...initialForm, operatorId: operator?.id, page: initialForm.operatorId == operator?.id? initialForm.page : 1});
        history.push({search: params.toString()});
    }

    const finishFilter = (data) => {
        data = {...data}
        let queryObj = {...getFormattedBody({...data}), operatorId: selectedOperator, page : 1};

        if (data.createdDate && data.createdDate.length) {
            queryObj["createdDateStart"] =  data.createdDate[0].toString();
            queryObj["createdDateEnd"] =  data.createdDate[1].toString();
        }
        delete queryObj["createdDate"]


        queryObj = {
            ...queryObj,
            page : Math.max(1, +queryObj.page || pagination.page),
            limit: Math.max(10, +queryObj.limit || pagination.limit)
        }

        const {params} = getUrlString(queryObj);
        history.push({search: params.toString()});
    }

    const handleCancel = () => {

    }
    return (
        <Space direction="horizontal" className="operator-duration">
            <UsersList selectUser={selectUser} selectedUserId={selectedOperator || getUrlValue(searchValue).operatorId}/>
            <Form
                form={form}
                onFinish={finishFilter}
                className="horizontal-form"
                layout="horizontal"
                validateTrigger="onBlur">
                <div className="wrap-content">
                    <Form.Item label={<FormattedMessage id={"date"}/>} name="createdDate">
                        <RangePicker getPopupContainer={triggerNode => triggerNode.parentElement}
                                    showTime
                                    ranges={{
                                        Today: [moment(), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')]
                                    }}
                                    format="DD-MM-YYYY HH:mm:ss"
                                    showToday={true}/>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id={"user_name"}/>} name="userName">
                        <Input placeholder={intl.formatMessage({id: "type_user_name"})}/>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={"type"}/>} name="type">
                        <Select allowClear
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                placeholder={intl.formatMessage({id: "select_type"})}>
                            {EventTypeArr.map((eventType, i)=> (
                                <Select.Option key={i} value={`${i}`}>
                                    <span className={classNames("event-type", EventType[i])}>
                                        <FormattedMessage id={eventType} />
                                    </span>
                                    
                                </Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </div>
                <Form.Item>
                    <button type="reset" className="miniBtn default" onClick={handleCancel}>
                        <FormattedMessage id='clear'/>
                    </button>
                    <Button type="primary"
                            htmlType="submit"
                            style={{height: 36}}
                            className="miniBtn submit"
                            loading={loading._add_client || loading._edit_client}
                    >
                        <FormattedMessage id='search'/>
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user?.profile,
        pagination: state.pagination
    }
}

const mapDispatchToProps = {
    getOperarorApiEvents,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatorApiEventsFilter));