import React, { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import { Input, Button, Upload, Progress, message } from 'antd';
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { getBase64 } from '../../utils/CommonUtils';

import uploadImg from "@assets/img/icon/uploadImg.svg";
import edit from "@assets/img/icon/edit.svg";
import delet from "@assets/img/icon/delete.svg";
import {GetToken} from "@services/AuthService";

function ImgUpload({value, onChange, onBlur, children, handleLoading, icon, uploadText, className, classNameByIcon, isOnlyImg = false, innerRef, ...resProps}) {
    const [loading, setLoading] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageName, setImageName] = useState('');
    const [progress, setProgress] = useState('');
    useEffect(() => {
        setImageUrl(value)
        setImagePreviewUrl(value);
        setLoading(false);
        setProgress('0');
    }, [value])

    useEffect(() => {
        handleLoading && handleLoading(loading)
    },[loading])

 
    const progressChange = (e) => {
        // setLoading(false);
        setProgress(Number(e.percent).toFixed(1));
    }
    const handleError = (err) => {
        setProgress('0');
        message.error("error_uploading");
    }
    const onEmitFile = (e) => {
        console.log(e)
    }
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setImageName(info.file.name);
            setLoading(true);
            setImagePreviewUrl('')
            return;
        }
        if (info.file.status === 'done') {
            setImageUrl(info.file.response);
            onChange(info.file.response);
            onBlur();
            getBase64(info.file.originFileObj, data => {
                setImagePreviewUrl(data);
                setLoading(false);
                setProgress('0');
            }
            );
        }
    }
    const changeFile = () => {
    }
    const deleteFile = (e) => {
        setImagePreviewUrl('');
        setImageUrl('');
        onChange('');
        onBlur();
        e.stopPropagation();
    }
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const LoadingProgres = (
        <div className="progress-bar">
            <Progress type="circle"percent={progress} width={50} status="active"/>
            <p className="text-capitalize"><FormattedMessage id="loading_image" /></p>
            {/* <p className="file-name one-row">{imageName}</p> */}
        </div>
    );
    const uploadButton = (
        <div className="uploadFileContent" ref={innerRef}>
            {loading && LoadingProgres || <>
                <p className="ant-upload-drag-icon">
                    {
                        icon? icon :  <img src={uploadImg} alt="icon" />
                    }
                </p>
                {
                    uploadText? <span className="text">{uploadText}</span> : <>
                        <p className="ant-upload-text"><FormattedMessage id='drag_and_drop_or' />
                            <span className="marked">
                                <FormattedMessage id='browse' />
                            </span>
                        </p>
                        <p className="ant-upload-hint">
                            <FormattedMessage id='jpeg_png_gif_less_than_2mb_min_width_400px' />
                        </p>
                    </>
                }
                
            </>}
        </div>
    );
    const previewBlock = (
        <div className="uploadFileContent" ref={innerRef}>
            <img src={imagePreviewUrl} alt="avatar" className="uploadFileContent--img" />
            { !isOnlyImg && 
            <div className="uploadFileContent--infoBox">
                <p className="uploadFileContent--infoBox--title">{imageName}</p>
                <div className="uploadFileContent--infoBox--btnGroup">
                    <Button onClick={changeFile} className="edit">
                        <span className="text"><FormattedMessage id={"change_image"}/></span>
                        <img src={edit} alt="edit"/>
                    </Button>
                    <Button onClick={deleteFile} className="delete">
                         <span className="text"><FormattedMessage id={"delete"}/></span>
                         <img src={delet} alt="delete"/>
                    </Button>
                </div>

            </div> ||
            <div className="show-by-hover"  onClick={changeFile}><FormattedMessage id="change" /></div> }
        </div>
    );
    return (
        <div className={classnames('uploadFileContent', className, imageUrl ? classNameByIcon : "",  {"uploaded": imagePreviewUrl})}>
            <Input type="hidden" {...resProps} value={imageUrl} onChange={onChange} />
            <Upload.Dragger name="file"
                listType="picture-card"
                disabled={loading}
                className={classnames({ "avatar-uploader": imagePreviewUrl, "preview-uploader": !imagePreviewUrl })}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onProgress={progressChange}
                action={process.env.SYSTEM_API_PATH + '/Images'}
                headers={{
                    Authorization: `Bearer ${GetToken()}`,
                }}
                onChange={handleChange}
                >
                {children ? children : imagePreviewUrl ? previewBlock : uploadButton}
            </Upload.Dragger>
        </div>
    )
}
function mapStateToProps(state) {
    return {
    }
}
const mapDispatchToProps = {
}

const ImgUploadComponent = connect(mapStateToProps, mapDispatchToProps)(ImgUpload);
export default React.forwardRef((props, ref) => <ImgUploadComponent  innerRef={ref} {...props} />);