export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const AvailableLanguage = {
    // 'en': "English",
    'ru': "Russian",
    'ar': "Arab",
    'bg': "Bulgarian",
    'es': "Spanish",
    'hi': "Indian",
    'it': "Italian",
    'pt': "Portuguese",
    'tr': "Turkish",
    'zh': "Chinese"
}

export const AvailableLanguageKays = Object.keys(AvailableLanguage);

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const TransactionType = {
    0: "bet",
    1: "win",
    2: "lose",
    3: "refund",
    // 5: "deposit"
}
export const TransactionTypeArr = Object.values(TransactionType);

export const TransactionStatus = {
    1: "success",
    2: "pending",
    3: "failed",
    4: "timeout"
}
export const TransactionStatusArr = Object.keys(TransactionStatus);

export const CsvProgresstype = {
    inprogress: 1,
    finished: 2,
    error: 3,
    cancelled: 4,
    emptyTable: 5
}

export const StatisticsDurationsType = ['today', 'yesterday', 'last_week', 'last_month', 'last_year']

export const EventType = {
    "0": 'gamestart',
    "1": 'placebet',
    "2": 'winbet',
    "3": 'lostbet',
    "4": 'refundbet'
}

export const EventTypeArr = Object.values(EventType);

export const DealersStatusType = {
    "active": 0,
    "inactive": 1
}
export const DealersStatus = ["active", "inactive"]