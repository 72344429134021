import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../utils/CommonUtils";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";
import {getGamesAssignment, clearGamesAssignment, deleteGamesAssignment} from "@actions/gamesAssignment";
import TableLoader from "@components/Loaders/tableLoader";
import PaginationLoader from "@components/Loaders/paginationLoader";
import GameAssignmentDeleteConfirmPopup from "@components/GameAssignmentDeleteConfirmPopup";
import {Table, Pagination ,Space, Tooltip, Typography} from "antd";
import HeaderTamplate from "@components/HeaderTamplate";
import GameAssignmentsFilter from "../GameAssignmentsFilter";
import {
    EditFilled,
    CopyFilled,
    DeleteFilled
} from "@ant-design/icons";
const { Paragraph, Text } = Typography;

function GameAssignments({intl, loading, user, pagination, getGamesAssignment, gamesAssignment, onEdit, deleteGamesAssignment, setDoneMsg, setErrorMsg }) {

    let history = useHistory();
    const search = history && history.location && history.location.search;
    const [confirmDeleteId, setConfirmDeleteId] = useState();

    useEffect(() => {
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            const loc = history && history.location && history.location.search;
            const initialForm = getGameAssignmentsRequestBody(loc);
            setPagination({
                page: initialForm.page || 1,
                limit: initialForm.limit || 10
            })
            getGamesAssignment({page: 1, limit: 10, ...initialForm});
        }
    }, [])


    const getGameAssignmentsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const loc = history && history.location && history.location.search;
        const initialForm = getGameAssignmentsRequestBody(loc);

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()});
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            getGamesAssignment({...initialForm, page: newPage, limit: changedLimit});
        }
    }

    const columnsGamesAssignment = [
        // {
        //     title: intl.formatMessage({id: "game_assignment_id"}),
        //     dataIndex: 'id',
        //     className: "copyable-column",
        //     render: (id, record) => {
        //         return <span>
        //                 <Text style={{ maxWidth: '100%' }} ellipsis={true}>
        //                     {id}
        //                 </Text>
        //                 <span className="copyable" onClick={(e) => {
        //                             copyLabel(e, record.id, setDoneMsg, setErrorMsg);
                                    
        //                             }}>
        //                     <CopyFilled />
        //                 </span>
        //         </span>
        //     }
        // },
        {
            title: intl.formatMessage({id: "operator_email"}),
            dataIndex: 'operator',
            render: (operator, _) => {
                return <>
                    <span>{operator?.email}</span>
                </>
            }
        },
        {
            title: intl.formatMessage({id: "game_name"}),
            dataIndex: 'game',
            render: (game, _) => {
                return <>
                    <span>{game.name}</span>
                </>
            }
        },
        {
            title: intl.formatMessage({id: "game_url"}),
            dataIndex: 'game',
            render: (game, _) => (
                <Tooltip placement="top"
                        overlayClassName="not-uppercase"
                        title={
                    <Paragraph copyable={{
                            icon: [<CopyFilled />]
                        }}>
                        {game.url}
                    </Paragraph>
                }>
                    <a href={game.url}>{game.url}</a>
                </Tooltip>
            )
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'id',
            render: (id, row) => (
                <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                    <Tooltip placement="top" title={<FormattedMessage id="edit"/>}>
                        <EditFilled onClick={() => onEdit(row)}/>
                    </Tooltip>
                    <span className="line-vertical"></span>
                    <Tooltip placement="top" title={<FormattedMessage id="delete"/>}>
                        <DeleteFilled onClick={() => setConfirmDeleteId(row.id)}/>
                    </Tooltip>
                </Space>
            )
        },
    ];

    const onDeleteGame = (id) => {
        deleteGamesAssignment({id});
    }

    useEffect(() => {
        const showingPadeEnd = pagination.page * pagination.limit
        const diff = showingPadeEnd - pagination.total;
        if (diff >= pagination.limit && pagination.total > 0) {
            handleTablePaginate(Math.max(pagination.page - 1, 1), pagination.limit);
        }
    }, [pagination.total])

    

    return (
        <>
            <div className="content-template mainTable">
            <HeaderTamplate className='game-assignments'
                title="game_assignments"
                uppendTitle={
                    <GameAssignmentsFilter />
                }
                changeDuration={false}
                lastUpdate={''}
                size={0}>
            {
                loading._assignment_games === undefined || loading._assignment_games ?
                <TableLoader count={13} column={3} actions={1} drag={false} icon={false} pagination={false}/> :
                <Table
                    dataSource={gamesAssignment}
                    columns={columnsGamesAssignment}
                    rowKey="id"
                    pagination={false}
                    />
            }
            </HeaderTamplate>
            </div>
            {
                loading._assignment_games === undefined || loading._assignment_games ?
                <PaginationLoader /> :
                <Pagination defaultCurrent={pagination.page}
                    className="ant-table-pagination ant-table-pagination-right"
                    total={pagination.total} 
                    showSizeChanger={true}
                    showLessItems={true}
                    loading={loading._assignment_games === undefined || loading._assignment_games}
                    onChange={(page, limit) => handleTablePaginate(page, limit)}
                    showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                    defaultPageSize={pagination.limit}/>
            }

            <GameAssignmentDeleteConfirmPopup onConfirm={() => {
                                        onDeleteGame(confirmDeleteId);
                                        setConfirmDeleteId(null);
                                    }}
                                    onCancel={() => setConfirmDeleteId(null)}
                                    loading={loading._delete_game}
                                    show={confirmDeleteId} />
        </>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.loading,
        pagination: state.pagination,
        user: state.user?.profile,
        gamesAssignment: state.gamesAssignment
    }
}

const mapDispatchToProps = {
    getGamesAssignment,
    clearGamesAssignment,
    deleteGamesAssignment,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameAssignments));

