import {
    REQUEST_ROUND_REPORT,
    REQUEST_DELETE_ROUND
} from "@actions/actionTypes";
import moment from "moment-timezone";


export function getRoundReport(data) {
    return dispatch => {
        if (data.dateRange) {
            data.Range = {};
        }
        data.dateRange?.start && (data.StartDateFrom = moment(data.dateRange.start).unix());
        data.dateRange?.end && (data.StartDateTo = moment(data.dateRange.end).unix());
        delete data.dateRange;

        //data.StartDateFrom && (data.StartDateFrom = moment(data.StartDateFrom).unix());
        //data.StartDateTo && (data.StartDateTo = moment(data.StartDateTo).unix());

        dispatch({type: REQUEST_ROUND_REPORT, data});
    }
}


export function deleteRound(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_DELETE_ROUND,
            data: {id},
            isOpenedByPopup: true
        });
    }
}