import {
    REQUEST_EDIT_OPERATOR_API_KEY,
    CLEAR_OPERATOR_API_KEYS,
    REQUEST_ADD_OPERATOR_API_KEY,
    REQUEST_DELETE_OPERATOR_API_KEY,
    REQUEST_OPERATOR_API_KEYS
} from "@actions/actionTypes";


export function getOperarorApiKays(data) {
    return dispatch => {
        dispatch({type: REQUEST_OPERATOR_API_KEYS, data});
    }
}

export function addOperarorApiKay(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_OPERATOR_API_KEY,
            data,
            isOpenedByPopup: true
        });
    }
}


export function editOperarorApiKay(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_OPERATOR_API_KEY,
            data,
            isOpenedByPopup: true
        });
    }
}
export function deleteOperarorApiKay(id) {
    return dispatch => {
        dispatch({type: REQUEST_DELETE_OPERATOR_API_KEY, data: {id}});
    }
}

export function clearOperarorApiKays() {
    return dispatch => {
        dispatch({type: CLEAR_OPERATOR_API_KEYS});
    }
}