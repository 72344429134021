import React from "react";
import {Table, Skeleton} from "antd";
import GameLoader from "./gameLoader";

const gamesLoader = ({count = 6}) => {
    
    return (
        <div className="gamesLoader">
            {
                [...(new Array(count).keys())].map((_, i) => <GameLoader key={i} />)
            }
        </div>
    )
}

export default gamesLoader