import React, {useEffect, useState} from 'react';
import { Menu } from 'antd';

  import { Link,  useLocation, useHistory } from "react-router-dom";
  import {FormattedMessage} from "react-intl";
  import "./style.sass";
  import dashboardIcon from "@assets/img/icon/dashboard.svg";
  import gamesIcon from "@assets/img/icon/games.svg";
  import settingsIcon from "@assets/img/icon/settings.svg";
  import transactionsIcon from "@assets/img/icon/transactions.svg";
  import dealerIcon from "@assets/img/icon/dealers.svg";

function LeftBare({isAdmin}) {
    const localHistory = useHistory();
    let location = useLocation();
    const [path, setPath] = useState(localHistory.location.pathname)

    useEffect(() => {
        let path = location.pathname;
        if (path.indexOf('deposit_groups_detals') !== -1) {
            path = '/deposit_groups';
        }
        setPath(path);
    },[location])

    const getPathParams = (path) => {
        let currentPath = location.pathname;
        let params = '';
        if (currentPath.indexOf(path) !== -1) {
            params = location.search;
        }
        return path + params;
    }

    return (
        <Menu  mode="inline" selectedKeys={[path]} className='left-bar-block'>
            <Menu.Item key="/dashboard" icon={<img src={dashboardIcon}/>}>
                <Link  to={getPathParams("/dashboard")}><FormattedMessage id='dashboard' /></Link >
            </Menu.Item>
            {
                isAdmin? <Menu.Item key="/categories" icon={<img src={gamesIcon}/>}>
                <Link  to={getPathParams("/categories")}><FormattedMessage id='categories' /></Link >
            </Menu.Item> : ""
            }
            <Menu.Item key="/games" icon={<img src={gamesIcon}/>}>
                <Link  to={getPathParams("/games")}><FormattedMessage id='games' /></Link >
            </Menu.Item>
            <Menu.Item key="/settings" icon={<img src={settingsIcon}/>} >
                <Link  to={getPathParams("/settings")}><FormattedMessage id={isAdmin? "operator_settings" : "settings"} /></Link >
            </Menu.Item>
            <Menu.Item key="/transactions" icon={<img src={transactionsIcon}/>} >
                <Link  to={getPathParams("/transactions")}><FormattedMessage id="transactions" /></Link >
            </Menu.Item>
            {/*<Menu.Item key="/dealers" icon={<img src={dealerIcon}/>} >
                <Link  to={getPathParams("/dealers")}><FormattedMessage id="dealers" /></Link >
            </Menu.Item>*/}
            
            {
                isAdmin? <Menu.Item key="/games-report" icon={<img src={transactionsIcon}/>} >
                <Link  to={getPathParams("/games-report")}><FormattedMessage id="games_report" /></Link >
            </Menu.Item> : ""
            }
            <Menu.Item key="/players-report" icon={<img src={dealerIcon}/>} >
                <Link  to={getPathParams("/players-report")}><FormattedMessage id="players_report" /></Link >
            </Menu.Item>
            <Menu.Item key="/round-report" icon={<img src={dealerIcon}/>} >
                <Link  to={getPathParams("/round-report")}><FormattedMessage id="round_report" /></Link >
            </Menu.Item>
        </Menu>
    )
}

export default LeftBare;