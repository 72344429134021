import {combineReducers} from "redux";
import {
    userReducer as user,
    isEndSilentRequestReducer as isEndSilentRequest
} from "./userReducer";
import error from "./errorReducer";
import langList from "./langListReducer";

import loading from "./loadingReducer";
import pagination from "./paginationReducer";
import responseErrors from "./responseErrorsReducer";
import path from "./pathReducer";
import csvAllowCancel from "./csvAllowCancelReducer";
import settings from "./settingsReducer";

import operatorApiEvents from "./operatorApiEventsReducer";
import games from "./gamesReducer";
import notifications from "./notificationReducer";
import users from "./usersReducer";
import gamesAssignment from "./gamesAssignmentReducer";
import operatorApiKeys from "./operatorApiKeysReducer";
import currencies from "./currenciesReducer";
import countries from "./countriesReducer";
import transactions from "./transactionsReducer";
import categories from "./categoriesReducer";
import dealers from "./dealersReducer";
import gamesReport from "./gamesReportReducer";
import playersReport from "./playersReportReducer";
import gamesAll from "./gamesAllReducer";
import roundReport from "./roundReportReducer";

const rootReducer = combineReducers({
    user,
    isEndSilentRequest,
    langList,
    error,
    loading,
    pagination,
    responseErrors,
    path,
    csvAllowCancel,
    settings,

    operatorApiEvents,
    games,
    notifications,
    users,
    gamesAssignment,
    operatorApiKeys,
    currencies,
    countries,
    transactions,
    categories,
    dealers,
    gamesReport,
    playersReport,
    gamesAll,
    roundReport
});

export default rootReducer;
