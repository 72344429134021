import React, { useState } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import { useEffect } from "react";
import {
    getGamesSelectAll
} from "@actions/gamesActions";

const { Option } = Select;

function GameSelectAll({gamesAll, intl, getGamesSelectAll, value, onChange, loading, ...anyProps}) {
    const [gamesSortedByNameAll, setGamesSortedByNameAll] = useState([]);
    console.log("calling..", gamesAll)
    useEffect(() => {
        getGamesSelectAll();
    }, [])

    useEffect(() => {
        console.log("gamedata: ", gamesAll)
        if(gamesAll) {
        setGamesSortedByNameAll(gamesAll.slice().sort((a, b) => {
                let aName = a.name.toLowerCase();
                let bName = b.name.toLowerCase();
                if (aName > bName) {
                    return 1;
                } else if (aName < bName) {
                   return -1;
                } else {
                    return 0;
                }
            }))
        }
    }, [gamesAll])

    
    return (
        <Select
            dropdownClassName="games-dropdown"
            showSearch
            placeholder={intl.formatMessage({id: "select_game"})}
            optionFilterProp="children"
            value={value}
            onChange={onChange}
            {...anyProps}
            allowClear
            loading={loading._games_select_all}
            filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                gamesSortedByNameAll.map(game => (<Option key={game.id} value={game.id}>{game.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        gamesAll: state.gamesAll
    }
}

const mapDispatchToProps = {
    getGamesSelectAll
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameSelectAll));