import initialStore from "../initialStore";
import {
    SET_USERS,
    CLEAR_USERS,
} from "@actions/actionTypes";

export default function usersReducer(state = initialStore.users, action) {
    switch (action.type) {
        case SET_USERS:
            return action.users;
        case CLEAR_USERS:
            return initialStore.emptyArr;
        default:
            return state;
    }
}
