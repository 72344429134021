import React, {useEffect, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table, Space, Tooltip, Typography} from "antd";
import { setDoneMsg, setErrorMsg } from "@actions/alertAction";
import { REQUEST_DELETE_CATEGORY } from "@actions/actionTypes";
import DragHandle from "@components/DragAndDrope/DragHandle";
import {getCategories, deleteCategory, setCategory, sortCategory} from "@actions/categoriesActions";
import {AvailableLanguage, AvailableLanguageKays} from "../../../constants/Enums";
import { DeleteFilled, EditFilled, CopyFilled, PlusOutlined } from '@ant-design/icons';
import DeleteCategoryConfirmPopup from "@components/DeleteCategoryConfirmPopup";
import CategoryControlPopup from "@components/CategoryControlPopup";
import DraggableContainer from "./DraggableContainer";
import DraggableBodyRow from "./DraggableBodyRow";

import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
import "./style.sass";
import {arrayMoveImmutable} from 'array-move';
const {Text} = Typography;
import CustomTable from "@components/CustomTable";

function Categories({intl, pagination, loading, user, categories, getCategories, deleteCategory, setDoneMsg, setErrorMsg, setCategory, sortCategory}) {

    let history = useHistory();
    const search = history && history.location && history.location.search;
    const [showFilter, setShowFilter] = useState(false);
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [categoryPopupMode, setCategoryPopupMode] = useState();
    const [confirmDeleteId, setConfirmDeleteId] = useState();
    const [singleCategory, setSingleCategory] = useState({});

    useEffect(() => {
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            const loc = history && history.location && history.location.search;
            const initialForm = getApiEventsRequestBody(loc);
           
            getCategories({...initialForm, operatorId: user.profile.sub});
        } else {
            getCategories();
        }
    }, [])

    useEffect(() => {
        if (confirmDeleteId) {
            subscribeForError(REQUEST_DELETE_CATEGORY, () => {
                setConfirmDeleteId(null);
            }, confirmDeleteId);
        }
        
        return () => {
            unSubscribeForError(REQUEST_DELETE_CATEGORY);
        }
    }, [confirmDeleteId])

    const columns = [
        {
            className: 'drag-visible noPadding',
            render: () => <DragHandle/>,
            width: 40,
        },
        {
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            className: "copyable-column drag-visible",
            render: (id, record) => {
                return  <Tooltip title={id} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {id}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, id, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        },
        {
            title: intl.formatMessage({id: "name"}),
            dataIndex: 'name',
            className: "drag-visible",
        },
        {
            title: intl.formatMessage({id: "English"}),
            dataIndex: 'translations',
            className: "drag-visible",
            render: (translations, row) => {
                return translations.find(item => item.language == "en")?.value || '';
            }
        }
    ];

    AvailableLanguageKays.forEach(lang => {
        columns.push({
            title: intl.formatMessage({id: AvailableLanguage[lang]}),
            dataIndex: 'translations',
            className: "drag-visible",
            render: (translations, row) => {
                return translations.find(item => item.language == lang)?.value || '';
            }
        })
    })

    const actionsColumn = [{
        title: intl.formatMessage({id: 'actions'}),
        fixed: 'right',
        className: "actions-column",
        render: (item, row) => (
            <Space direction="horizontal" className="game-actions" size={[21, 0]}>
                <Tooltip placement="top" title={<FormattedMessage id="edit"/>}>
                    <EditFilled onClick={() => {
                       setSingleCategory(row);
                       setCategoryPopupMode("edit");
                    }}/>
                </Tooltip>
                <span className="line-vertical"></span>
                <Tooltip placement="top" title={<FormattedMessage id="delete"/>}>
                    <DeleteFilled onClick={() => onDelete(row)}/>
                </Tooltip>
            </Space> 
        )
      }]

      const onDelete = (item) => {
        setConfirmDeleteId(item.id);
    }

    const onDeleteCategory = (id) => {
        deleteCategory(id);
    }

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
       
        const loc = history && history.location && history.location.search;
        const initialForm = getApiEventsRequestBody(loc);

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()});
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            getCategories({...initialForm, page: newPage, limit: changedLimit, operatorId: user.profile.sub});
        }

    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(categories || []), oldIndex, newIndex).filter(el => !!el);
            setCategory(newData);
            const body = JSON.parse(JSON.stringify(newData));
            body.forEach((item, index) => {
                item.orderId = (index + 1);
            })
            sortCategory(body)
        }
    };

    return (
        <div className="content categories">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='categories'/>
                </div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn"
                            style={{border: 'unset'}}
                            onClick={() => setCategoryPopupMode("add")}>
                        <div className="icon"/>
                        <FormattedMessage id='add_category'/>
                    </button>
                </div>
            </div>
            <div className="categories" style={{maxWidth: '100%'}}>
                <div className="mainTable" style={{maxWidth: '100%'}}>
                    {loading._categories === undefined || loading._categories ?
                    <TableLoader count={pagination.limit} column={8} icon={false} drag={false} actions={1} pagination={false}/> :
                        <CustomTable actionsColumn={actionsColumn}
                                     tableColumn={columns}
                                     className="event-table"
                                     dataSource={categories}
                                     tableScrollX={1500}
                                     components={{
                                         body: {
                                             wrapper: (props) => <DraggableContainer lockAxis={"y"} onSortEnd={onSortEnd} {...props} />,
                                             row: (props) => <DraggableBodyRow {...props} data={categories || []}/>,
                                         },
                                     }}/>
                    }
                </div>
            </div>
            <DeleteCategoryConfirmPopup onConfirm={() => {
                                        onDeleteCategory(confirmDeleteId);
                                    }}
                                    onCancel={() => setConfirmDeleteId(null)}
                                    loading={loading["_delete_category" + confirmDeleteId]}
                                    show={confirmDeleteId} />
            {
                categoryPopupMode? <CategoryControlPopup handleCancel={
                                                    () => {
                                                        setCategoryPopupMode("");
                                                        setSingleCategory({})
                                                    }
                                                }
                                                show={categoryPopupMode}
                                                category={singleCategory}/> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        categories: state.categories,
        loading: state.loading,
        pagination: state.pagination,
        user: state.user
    }
}

const mapDispatchToProps = {
    getCategories,
    setDoneMsg,
    setErrorMsg,
    deleteCategory,
    setCategory,
    sortCategory
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Categories));