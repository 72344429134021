import {
    REQUEST_COUNTRIES,
    SET_COUNTRIES,
} from "@actions/actionTypes";


export function getCountries() {
    return dispatch => {
        dispatch({type: REQUEST_COUNTRIES});
    }
}

export function clearCountries() {
    return dispatch => {
        dispatch({type: SET_COUNTRIES});
    }
}