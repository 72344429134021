import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import { Space, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import UserList from "./List";

function UsersDropdownList({intl, users, onSelectOperator, selecktedKeys}) {

    const [search, setSearch] = useState("");

    const onSelectUser = ({key}) => {
        onSelectOperator(users.find(user => user.id == key))
    }

    const searchHandel = (e) => {
        setSearch(e.target.value);
    }

    return (
        <Space direction="vertical" style={{minWidth: 310}}>
            <div className="search-block">
                <Input
                    onInput={searchHandel}
                    placeholder={intl.formatMessage({id: "start_search"})}
                    prefix={<SearchOutlined />}
                    />
            </div>
            <UserList selecktedKeys={selecktedKeys}
                        users={users}
                        search={search}
                        onSelectUser={onSelectUser}/>
        </Space>
    )
}


function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users
    }
}

const mapDispatchToProps = {
    
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UsersDropdownList));