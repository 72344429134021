import initialStore from "../initialStore";
import { SET_DEALERS, SET_EDIT_DEALER, SET_ADD_DEALER} from "@actions/actionTypes";

export default function dealersReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_DEALERS:
            return action.dealers;
        // case SET_EDIT_DEALER:
        //     return initialStore.emptyArr;
         // case SET_ADD_DEALER:
        //     return initialStore.emptyArr;
        default:
            return state;
    }
}