import initialStore from "../initialStore";
import { CHANGE_LOADING, CLEAR_WALLETS, CLEAR_CLIENTS } from "@actions/actionTypes";

export default function loadingReducer(state = initialStore.emeptyObj, action) {
    switch (action.type) {
        case CHANGE_LOADING:
            return {...state, ...action.loading};
        // case CLEAR_WALLETS: {
        //     let newState = Object.assign({}, state);
        //     delete newState['_wallets'];
        //     return newState;
        // }
        

        default:
            return state;
    }
}
