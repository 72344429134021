import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_TRANSACTIONS,
    SET_TRANSACTIONS,
    UPDATE_PAGINATION_TOTAL
} from "@actions/actionTypes";
import {GetResource} from "../../utils/HttpUtil";
import {getQuery} from "../../utils/CommonUtils"; 
import {safe, onError} from "./handlerError";


function* fetchTransactions({data}) {
    const query = getQuery({...data, SortBy: 'CreateDate', SortOrder: 'Desc'});
    const transactions =  yield call(GetResource, `/Transactions?${query}`);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: transactions.totalCount});
    yield put({type: SET_TRANSACTIONS, transactions: transactions.data});
}

function* transactionsSaga() {
    yield takeLatest(REQUEST_TRANSACTIONS, safe(onError, fetchTransactions));
}

export default transactionsSaga;