import React from "react";
import SortableContainer from "./SortableContainer";

const DraggableContainer = props => {

    return (
        <SortableContainer
            {...props}
            useDragHandle={true}
            getContainer={() => {
                return document.querySelector("section.ant-layout.site-layout");
            }}
            helperClass="row-dragging"
            onSortEnd={props.onSortEnd}

        />
    )
}

export default DraggableContainer;