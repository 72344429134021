import initialStore from "../initialStore";
import { SET_CURRENCY, CLEAR_CURRENCY} from "@actions/actionTypes";

export default function currenciesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_CURRENCY:
            return action.currencies;
        case CLEAR_CURRENCY:
            return initialStore.emptyArr;
        default:
            return state;
    }
}