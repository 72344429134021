import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_GAMES,
    SET_GAMES,
    REQUEST_ADD_GAME,
    SET_ADD_GAME,
    UPDATE_PAGINATION_TOTAL,
    REQUEST_DELETE_GAME,
    SET_EDIT_GAME,
    REQUEST_EDIT_GAME,
    SET_DELETE_GAME,
    SET_ERROR_MSG,
    SET_GAMES_SELECT,
    REQUEST_GAMES_SELECT,
    REQUEST_GAMES_SELECT_ALL,
    SET_GAMES_SELECT_ALL,
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource, DeleteResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

let prevPage = "1", prevLimit = "100";

function* fetchGames({data}) {
    data?.page && (prevPage = data.page);
    data?.limit && (prevLimit = data.limit);
    const games =  yield call(GetResource, `/gameassignfilter?SortBy=CreateDate&SortOrder=desc&page=${prevPage}&limit=${prevLimit}`);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: games.totalCount});
    yield put({type: SET_GAMES, games: games.data});
}

function* fetchGamesForSelect({data}) {
    const games =  yield call(GetResource, `/gameassignfilter?SortBy=CreateDate&SortOrder=desc&page=${1}&limit=${1000}`);
    yield put({type: SET_GAMES_SELECT, gamesSelect: games.data});
}

function* fetchAllGamesForSelect({data}) {
    const games =  yield call(GetResource, `/Games?SortBy=CreateDate&SortOrder=desc&page=${1}&limit=${1000}`);
    yield put({type: SET_GAMES_SELECT_ALL, gamesSelectAll: games.data});
}

function* addGames({data}) {
    const games =  yield call(PostResource, '/Games', data);
    yield put({type: SET_ADD_GAME, games: games.data});
    yield put({
        type: REQUEST_GAMES,
        data: {
            limit: prevLimit,
            page: prevPage
        }
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editGames({data}) {
    const game =  yield call(PutResource, `/Games/${data.id}`, data);
    yield put({type: SET_EDIT_GAME, data: game});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* deleteGames({data}) {
    yield call(DeleteResource, `/Games/${data.id}`);
    yield put({type: SET_DELETE_GAME, data});
    yield put({
        type: REQUEST_GAMES,
        data: {
            limit: prevLimit,
            page: prevPage
        }
    })
}

function* gamesSaga() {
    yield takeLatest(REQUEST_GAMES, safe(onError, fetchGames));
    yield takeLatest(REQUEST_GAMES_SELECT, safe(onError, fetchGamesForSelect));
    yield takeLatest(REQUEST_GAMES_SELECT_ALL, safe(onError, fetchAllGamesForSelect));
    yield takeLatest(REQUEST_ADD_GAME, safe(onError, addGames));
    yield takeLatest(REQUEST_DELETE_GAME, safe(onError, deleteGames));
    yield takeLatest(REQUEST_EDIT_GAME, safe(onError, editGames));
}

export default gamesSaga;