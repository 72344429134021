import React, { useEffect, useRef, useState } from 'react'
import { Layout, Dropdown, Tooltip, Badge } from 'antd';
import UserAccount from "../UserAccount";
import "./style.sass";
import moment from 'moment';
import SwitchToIcon from "@assets/img/icon/switch_to.svg";
import SwitchTo from "@components/SwitchTo";
// import Notification from "@components/Notification";
import {FormattedMessage} from "react-intl";
import logo_tembo from "@assets/img/icon/switch_to/tembo.svg";
import classnames from 'classnames';

const { Header } = Layout;

function MainHeader({apps, isSuperAdmin}) {
    const [isVisibleSwitchTo, setIsVisibleSwitchTo] = useState(false);
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
    const [showSwitchTo, setShowSwitchTo] = useState(false);
    
    const getTime = () => {
        let now = new Date();
        return moment(now).format('DD.MM.YYYY HH:mm 111 ZZ').toString().replace('111', 'GMT');
    }

    const [time, setTime] = useState(getTime());
    const timer = useRef();

    useEffect(() => {
        setIsVisibleTooltip(false)
    }, [isVisibleSwitchTo])

    useEffect(() => {
        timer.current = setInterval(() => {
            setTime(getTime());
        }, 10000);
        return () => {
            clearInterval(timer.current);
        }
    }, [])

    useEffect(() => {
        setShowSwitchTo(apps?.length && apps.length > 1 || isSuperAdmin)
    }, [apps])

    return (
        <Header className="site-layout-background header">
            <div >
                <div className={classnames("logo-site", {show: showSwitchTo})}>
                    <Dropdown overlayClassName="logoDropDown" overlay={<SwitchTo />} placement="bottomLeft" trigger="click" onVisibleChange={setIsVisibleSwitchTo}>
                        <Tooltip placement="bottomLeft" title={<FormattedMessage id="switch_to_" />} visible={isVisibleTooltip && !isVisibleSwitchTo} onVisibleChange={setIsVisibleTooltip}>
                            <img src={SwitchToIcon} />
                        </Tooltip>
                    </Dropdown>
                </div>
                <div className="time-block centeredBox">
                    <img src={logo_tembo} />
                    <span>Tembo</span>
                    {/* {time} */}
                </div>
                {/* <div className='notification'>
                    <Notification />
                </div> */}
                <div className="header-account">
                    <UserAccount />
                </div>
            </div>
        </Header>
    )
}

export default MainHeader;