import {
    REQUEST_USERS,
    CLEAR_USERS
} from "@actions/actionTypes";

export function getUsers() {
    return dispatch => {
         dispatch({
            type: REQUEST_USERS
        })
    }
}

export function clearUsers() {
    return dispatch => {
         dispatch({
            type: CLEAR_USERS
        })
    }
}