import initialStore from "../initialStore";
import { SET_ASSIGNMENT_GAMES, SET_EDIT_ASSIGNMENT_GAME } from "@actions/actionTypes";

export default function gamesAssignmentReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_ASSIGNMENT_GAMES:
            return action.games;
        case SET_EDIT_ASSIGNMENT_GAME:
            return state.map(gamesAssignment => {
                if (gamesAssignment.id == action.data.id) {
                    return action.data;
                }
                return gamesAssignment;
            })
        default:
            return state;
    }
}