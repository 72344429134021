import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_OPERATOR_API_EVENTS,
    SET_OPERATOR_API_EVENTS,
    UPDATE_PAGINATION_TOTAL
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import "./csvProcess";


function* fetchOperatorApiEvents({data}) {
    const operatorApiEvents =  yield call(GetResource, `/Events/${data.operatorId}?SortBy=CreateDate&SortOrder=Desc&page=${data.page}&limit=${data.limit}` + (data.type ? `&type=${data.type}` : '') + (data.createdDateStart ? `&from=${data.createdDateStart}` : '') + (data.createdDateEnd ? `&to=${data.createdDateEnd}` : '') + (data.userName ? `&username=${data.userName}` : ''));
    yield put({type: UPDATE_PAGINATION_TOTAL, total: operatorApiEvents.totalCount});
    yield put({type: SET_OPERATOR_API_EVENTS, data: operatorApiEvents.data});
}

function* operatorApiEventsSaga() {
    yield takeLatest(REQUEST_OPERATOR_API_EVENTS, safe(onError, fetchOperatorApiEvents));
}

export default operatorApiEventsSaga;