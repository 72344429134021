import React from "react";
import SortableItem from "./SortableItem";

const DraggableBodyRow = ({className, data, style, ...restProps}) => {
    if (data?.length) {
        const index = data.findIndex(x => x.id === restProps['data-row-key']);
        if (index !== -1) {
            return <SortableItem index={index} {...restProps}  />
        }
    }
    return false;
};

export default DraggableBodyRow;