import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_CURRENCY,
    SET_CURRENCY,
    REQUEST_COUNTRIES,
    SET_COUNTRIES
} from "@actions/actionTypes";
import {GetResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";


function* fetchCurrency({}) {
    const currencies =  yield call(GetResource, `/Currencies`);
    yield put({type: SET_CURRENCY, currencies});
}

function* fetchCountries({data}) {
    const countries =  yield call(GetResource, `/Countries`);
    yield put({type: SET_COUNTRIES, countries});
}

function* globalApiSaga() {
    yield takeLatest(REQUEST_CURRENCY, safe(onError, fetchCurrency));
    yield takeLatest(REQUEST_COUNTRIES, safe(onError, fetchCountries));
}

export default globalApiSaga;