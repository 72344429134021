import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import PaginationLoader from "@components/Loaders/paginationLoader";
import {Table, Pagination, Space, Tooltip, Typography} from "antd";
import moment from "moment-timezone";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";
import HeaderTamplate from "@components/HeaderTamplate";
import PlayersReportFilter from "@components/PlayersReportFilter";

import {getPlayersReport} from "@actions/playersReportActions";
import { CopyFilled } from '@ant-design/icons';
import {
    InfoCircleFilled
} from "@ant-design/icons";
import {StatisticsDurationsType, TransactionType} from "../../../constants/Enums";
import "./style.sass";

const {Text} = Typography;

function PlayersReport({intl, pagination, loading, user, playersReport, allowCancel, setPagination, getPlayersReport, setDoneMsg, setErrorMsg}) {

    let history = useHistory();
    const search = history && history.location && history.location.search;
    const [showFilter, setShowFilter] = useState(true);
    useEffect(() => {
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            const loc = history && history.location && history.location.search;
            const initialForm = getApiEventsRequestBody(loc);
            setPagination({
                page: initialForm.page || 1,
                limit: initialForm.limit || 10
            })
            getPlayersReport({page: 1, limit: 10, ...initialForm, operatorId: user.profile.sub});
        }
    }, [])

    const columns = [
        /*{
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            className: "copyable-column",
            render: (id, record) => {
                return  <Tooltip title={id} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {id}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, id, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        },*/
       
        {
            title: intl.formatMessage({id: "player_id"}),
            dataIndex: 'playerId',
            className: "copyable-column",
            render: (playerId, record) => {
                return  <Tooltip title={playerId} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {playerId}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, playerId, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        },
        {
            title: intl.formatMessage({id: "operator"}),
            dataIndex: 'operator'
        },
        {
            title: intl.formatMessage({id: "games"}),
            dataIndex: 'games'
        },
        {
            title: intl.formatMessage({id: "bet"}),
            dataIndex: 'bet'
        },
        {
            title: intl.formatMessage({id: "result"}),
            dataIndex: 'result'
        },
        
        {
            title: intl.formatMessage({id: "payout"}),
            dataIndex: 'payout',
            render: (payout) => {
                return +payout.toFixed(2)
            }
           
        },
        
        {
            title: intl.formatMessage({id: "currency"}),
            dataIndex: 'currency',
            className: "text-left"
        },
        /*{
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'date',
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let time = moment(record.date).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")
                return <Tooltip title={time} color={"#fff"} placement="top">
                        {time}
                    </Tooltip>
            }

        }*/
    ];
    /*if (user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin) {
        columns.splice(1, 0, {
            title: intl.formatMessage({id: "operator_id"}),
            dataIndex: 'operatorId',
            className: "copyable-column",
            render: (operatorId, record) => {
                return  <Tooltip title={operatorId} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {operatorId}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, operatorId, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        })
    }*/

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }

    const exportCVS = () => {
        console.log("exportCVS click")
    }

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const loc = history && history.location && history.location.search;
        const initialForm = getApiEventsRequestBody(loc);

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()});
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            getPlayersReport({...initialForm, page: newPage, limit: changedLimit, operatorId: user.profile.sub});
        }

    }

    const cancelTransactionsCsvDownload = () => {
        console.log("cancel")
    }

    return (
        <div className="content playersReport">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='players_report'/>
                </div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <div className="playersreport" style={{maxWidth: '100%'}}>
                <div className="mainTable" style={{maxWidth: '100%'}}>
                    <HeaderTamplate className='playersreport-block'
                        title="players_report"
                        changeDuration={false}
                        lastUpdate={''}
                        size={24}>
                            {
                                <div className="operator-duration-block">
                                    <PlayersReportFilter showFilter={showFilter}/>
                                    {loading._players_report === undefined || loading._players_report ?
                                    <TableLoader count={pagination.limit} column={5} icon={false} drag={false} actions={0} pagination={false}/> :
                                    <Table dataSource={playersReport}
                                        columns={columns}
                                        className="event-table"
                                        rowKey="playerId"
                                        scroll={{x: 1000}}
                                        pagination={false}
                                    />}
                                </div>
                            }
                    </HeaderTamplate>
                    <Space direction="horizontal" className="bottom-bar">
                        <CsvDownloader exportCVS={exportCVS}
                                    titleDownload="load_events"
                                    downloadLoading={loading._players_report_csv_downloader_url}
                                    cancelLoading={loading._cancel_players_report_csv_downloader_url}
                                    onCancel={cancelTransactionsCsvDownload}
                                    allowCancel={allowCancel}/>
                        {
                            loading._players_report === undefined || loading._players_report ?
                            <PaginationLoader /> :
                            <Pagination defaultCurrent={pagination.page}
                                className="ant-table-pagination ant-table-pagination-right"
                                total={pagination.total} 
                                showSizeChanger={true}
                                showLessItems={true}
                                loading={loading._players_report === undefined || loading._players_report}
                                onChange={(page, limit) => handleTablePaginate(page, limit)}
                                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                                defaultPageSize={pagination.limit}/>
                        }
                        
                    </Space>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        playersReport: state.playersReport,
        loading: state.loading,
        pagination: state.pagination,
        allowCancel: state.csvAllowCancel.allowTransactions,
        user: state.user
    }
}

const mapDispatchToProps = {
    getPlayersReport,
    setPagination,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlayersReport));