import React, {useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    Dropdown,
    Space,
    Typography
} from "antd";
import {
    getUsers,
    clearUsers
} from "@actions/usersActions";
import UsersDropdownList from "./UsersDropdownList";
import { DownOutlined, StarFilled } from '@ant-design/icons';
import { useEffect } from "react";
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
const { Text } = Typography;

function UsersList({getUsers, clearUsers, users, selectUser, selectedUserId}) {

    const [visible, setVisible] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState();

    const onSelectOperator = (operator) => {
        setSelectedOperator(operator);
        selectUser(operator);
        setVisible(false);
    }

    useEffect(() => {
        getUsers();
        return () => {
            clearUsers();
        }
    }, [])

    useEffect(() => {
        if (users.length > 0) {
            setSelectedOperator(users.find(user => user.id == selectedUserId) || users[0]);
        }
    }, [selectedUserId])

    useEffect(() => {
        if (users.length) {
            if (selectedUserId) {
                onSelectOperator(users.find(user => user.id == selectedUserId) || users[0]);
            } else {
                onSelectOperator(users[0]);
            }
        }
    }, [users])

    return (
        <Dropdown
            className="client-list-input"
            overlay={
                <UsersDropdownList onSelectOperator={onSelectOperator}
                                    selecktedKeys={users.length? [selectedUserId? selectedUserId : users[0].id] : []}/>
                    }
            trigger={['click']}
            onVisibleChange={isVisible => setVisible(isVisible)}
            overlayClassName="operators-list-popup"
            visible={visible}
            >
            {
                selectedOperator?
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <Space direction="horizontal" size={[8, 0]} className="operator-item">
                        <img src={selectedOperator.image || UserEmptyImg} />
                        <Text ellipsis={true}>{selectedOperator.name}</Text>
                        <StarFilled />
                        <DownOutlined />
                    </Space>
                </a> : 
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <FormattedMessage id="select_operator" /> <DownOutlined />
                </a>
            }
            
        </Dropdown>
    )
}


function mapStateToProps(state) {
    return {
        users: state.users
    }
}

const mapDispatchToProps = {
    getUsers,
    clearUsers
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UsersList));