import moment from "moment-timezone";

export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: null,
    emeptyObj: {},
    emptyArr: [],
    pagination: {
        page: 1,
        limit: 10,
        total: 5000
    },
    notifications: [
      {
        title: "Game assignment updated",
        date: new Date(),
        isRead: true
      },
      {
        title: "API key updated",
        date: new Date(),
        isRead: false
      },
      {
        title: "API key updated",
        date: new Date(),
        isRead: true
      },
      {
        title: "API key updated",
        date: new Date(),
        isRead: false
      },
      {
        title: "Game assignment updated",
        date: new Date(),
        isRead: false
      },
      {
        title: "API key updated",
        date: new Date(),
        isRead: false
      },
      {
        title: "Game assignment updated",
        date: new Date(),
        isRead: false
      }
    ],
    allowCancel: {
        allowWallets: false,
        allowDeposits: false,
        allowTransaction: false
    },
    users: [],
    operatorApiKeys: [],
    operatorApiEvents: []
}