import {
    REQUEST_OPERATOR_API_EVENTS
} from "@actions/actionTypes";
import moment from "moment-timezone";

export function getOperarorApiEvents(data) {
    return dispatch => {
        if (data.operatorId == undefined) return;
        data.createdDateStart && (data.createdDateStart = moment(data.createdDateStart).unix());
        data.createdDateEnd && (data.createdDateEnd = moment(data.createdDateEnd).unix());
        dispatch({type: REQUEST_OPERATOR_API_EVENTS, data});
    }
}
