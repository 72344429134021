import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../utils/CommonUtils";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";
import {getGamesAssignment, clearGamesAssignment} from "@actions/gamesAssignment";
import TableLoader from "@components/Loaders/tableLoader";
import PaginationLoader from "@components/Loaders/paginationLoader";
import GameAssignmentDeleteConfirmPopup from "@components/GameAssignmentDeleteConfirmPopup";
import {Table, Pagination ,Space, Tooltip} from "antd";
import {
    EditFilled,
    CopyFilled,
    DeleteFilled
} from "@ant-design/icons";

function MyGame({intl, loading, pagination, getGamesAssignment, gamesAssignment, onEdit }) {
    let history = useHistory()
    const search = history && history.location && history.location.search

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchGamesAssignment(true)
    
        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])
    
    const getGameAssignmentsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }
    
    
    const fetchGamesAssignment = (isFirst) => {
        let initialForm = getGameAssignmentsRequestBody()
        initialForm = {
            ...initialForm,
            tab: 2,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            getGamesAssignment(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }
    
    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getGameAssignmentsRequestBody(loc)
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: Math.max(10, (+initialForm.limit || pagination.limit)),
                })
            }
        })
    },[history])
    
    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
    
        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const initialForm = getGameAssignmentsRequestBody()
    
        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})
    
    }

    const columnsGamesAssignment = [
        {
            title: intl.formatMessage({id: "game_assignment_id"}),
            dataIndex: 'id',
            render: (id, record) => {
                return <>
                    <span onDoubleClick={(e) => copyLabel(e, id, setDoneMsg, setErrorMsg)} >{id}</span>
                    <CopyFilled onClick={(e) => copyLabel(e, id, setDoneMsg, setErrorMsg)}/>
                </>
            }
        },
        {
            title: intl.formatMessage({id: "game_name"}),
            dataIndex: 'game',
            render: (game, _) => {
                return <>
                    <span>{game.name}</span>
                </>
            }
        },
        {
            title: intl.formatMessage({id: "game_url"}),
            dataIndex: 'game',
            render: (game, _) => {
                return <>
                    <a href={game.url}>{game.url}</a>
                </>
            }
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'id',
            render: (id, row) => (
                <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                    <Tooltip placement="top" title={<FormattedMessage id="edit"/>}>
                        <EditFilled onClick={() => onEdit(row)}/>
                    </Tooltip>
                </Space>
            )
        },
    ];

    return (
        <>
            <div className="content-template mainTable my-game" style={{minHeight: "calc(100% - 80px)"}}>
            {
                loading._assignment_games === undefined || loading._assignment_games ?
                <TableLoader count={13} column={3} actions={1} drag={false} icon={false} pagination={false}/> :
                <Table
                    dataSource={gamesAssignment}
                    columns={columnsGamesAssignment}
                    rowKey="id"
                    pagination={false}
                    />
            }

            </div>
            {
                loading._assignment_games === undefined || loading._assignment_games ?
                <PaginationLoader /> :
                <Pagination defaultCurrent={pagination.page}
                    className="ant-table-pagination ant-table-pagination-right"
                    total={pagination.total} 
                    showSizeChanger={true}
                    showLessItems={true}
                    loading={loading._assignment_games === undefined || loading._assignment_games}
                    onChange={(page, limit) => handleTablePaginate(page, limit)}
                    showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                    defaultPageSize={pagination.limit}/>
            }
        </>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.loading,
        pagination: state.pagination,
        user: state.user?.profile,
        gamesAssignment: state.gamesAssignment
    }
}

const mapDispatchToProps = {
    getGamesAssignment,
    clearGamesAssignment
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MyGame));