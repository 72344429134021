import initialStore from "../initialStore";
import { SET_COUNTRIES, CLEAR_COUNTRIES} from "@actions/actionTypes";

export default function countriesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_COUNTRIES:
            return action.countries;
        case CLEAR_COUNTRIES:
            return initialStore.emptyArr;
        default:
            return state;
    }
}