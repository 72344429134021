import React, { useState } from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import {Space, Form, Button, DatePicker, Select, Input} from "antd";
import UsersList from "@components/UsersList";
import classNames from "classnames";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import {TransactionType, TransactionTypeArr} from '../../constants/Enums';
import {getTransactions} from "@actions/transactionsActions";
import {setPagination} from "@actions/paginationsActions";
import {getUrlValue, getFormattedUrlObject, getUrlString, checkNumberValidation} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import moment from "moment-timezone";
import GameSelect from "@components/GameSelect";
import CurrencySelect from "@components/CurrencySelect";
import { useEffect } from "react";


function TransactionsFilter({setPagination, loading, intl, getTransactions, user, pagination, showFilter, ...anyProps}) {

    let history = useHistory()
    const searchValue = history && history.location && history.location.search

    const [search, setSearch] = useState(getUrlValue(searchValue))
    const [form] = Form.useForm();
    const [selectedOperator, setSelectedOperator] = useState(getUrlValue(searchValue).operatorId);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchOperarorApiEvents(true);

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])


    const getTransactionsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            dateRange:{
                start: initialForm["dateRange"] ? moment(initialForm["dateRange"]["start"]).utc().format() : "",
                end: initialForm["dateRange"] ? moment(initialForm["dateRange"]["end"]).utc().format() : ""
            },
        }
    }


    const fetchOperarorApiEvents = (isFirst) => {
        let initialForm = getTransactionsRequestBody();
        initialForm = {
            ...initialForm,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            if (!(user.IsAdmin || user?.IsSuperAdmin)) initialForm.operatorId = user.sub;
            // getTransactions(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location, action) => {
            if (location.pathname != "/transactions") return;
            const loc = location && location.search
            let initialForm = getUrlValue(loc);
            initialForm = {
                ...initialForm,
                page : Math.max(1, +initialForm.page || pagination.page),
                limit: Math.max(10, +initialForm.limit || pagination.limit)
            }
            setPagination({
                page: initialForm.page,
                limit: initialForm.limit,
            })

            getTransactions(getFormattedUrlObject({...initialForm}));

            if (initialForm.dateRangeStart || initialForm.dateRangeEnd) {
                initialForm.dateRange = [moment(initialForm["dateRangeStart"]) || "", moment(initialForm["dateRangeEnd"]) || ""]
            }

            delete initialForm.dateRangeStart;
            delete initialForm.dateRangeEnd;
            
            if (action === "POP") {
                setSelectedOperator(initialForm.operatorId);
            }
            form.setFieldsValue(getFormattedForForm(initialForm));
        })
    },[history])


    const selectUser = (operator) => {
        setSelectedOperator(operator?.id);
        const searchValue = history && history.location && history.location.search
        let initialForm = getTransactionsRequestBody(searchValue);

        setPagination({
            page: 1,
            limit: initialForm.limit,
        })

        const {params} = getUrlString({...initialForm, operatorId: operator.id, page: initialForm.operatorId == operator.id? initialForm.page : 1});
        history.push({search: params.toString()});
    }

    const finishFilter = (data) => {
        data = {...data}
        let queryObj = {...getFormattedBody({...data}), operatorId: selectedOperator, page : 1};

        if (data.dateRange && data.dateRange.length) {
            queryObj["dateRangeStart"] =  data.dateRange[0].toString();
            queryObj["dateRangeEnd"] =  data.dateRange[1].toString();
        }
        delete queryObj["dateRange"]

        queryObj = {
            ...queryObj,
            page : Math.max(1, +queryObj.page || pagination.page),
            limit: Math.max(10, +queryObj.limit || pagination.limit)
        }

        const {params} = getUrlString(queryObj);
        history.push({search: params.toString()});
    }

    const handleCancel = () => {

    }

    const exportCVS = () => {
        if (loading._deposits_csv_downloader_url === undefined || !loading._deposits_csv_downloader_url) {
            // getDepositsCsvLink(form.getFieldsValue())
        }
    }
    return (
        <div className="searchBar csv" {...anyProps}>
            <Form
                form={form}
                onFinish={finishFilter}
                className="horizontal-form"
                layout="horizontal"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    {user?.IsAdmin? <div className="searchBar--col">
                        <UsersList selectUser={selectUser} selectedUserId={selectedOperator || getUrlValue(searchValue).operatorId}/>
                    </div> : ""}
                    
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"game"}/>} name="gameId" className="game-select">
                            <GameSelect />
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"player_id"}/>} name="playerId">
                            <Input placeholder={intl.formatMessage({id: "type_player_id"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"currency"}/>} name="currencyId">
                            <CurrencySelect mode="false"/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"type"}/>} name="type">
                            <Select allowClear
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    placeholder={intl.formatMessage({id: "select_type"})}>
                                {TransactionTypeArr.map((type, i)=> (
                                    <Select.Option key={i} value={`${i}`}>
                                        <span className={classNames("transaction-type", TransactionType[i])}>
                                            <FormattedMessage id={type} />
                                        </span>
                                        
                                    </Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"date"}/>} name="dateRange">
                            <RangePicker getPopupContainer={triggerNode => triggerNode.parentElement}
                                        showTime
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')]
                                        }}
                                        format="DD-MM-YYYY HH:mm:ss"
                                        showToday={true}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"amount"}/>}>
                            <Form.Item name="amountRangeStart" noStyle>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountRangeEnd" noStyle>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox" style={{width: 176, maxWidth: 176}}>
                        <div className="filterPart">
                            <Form.Item>
                                <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                    <FormattedMessage id='clear'/>
                                </button>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{height: 36}}
                                        className="miniBtn submit"
                                        loading={loading._transactions}
                                >
                                    <FormattedMessage id='search'/>
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user?.profile,
        pagination: state.pagination
    }
}

const mapDispatchToProps = {
    getTransactions,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransactionsFilter));