import {
    REQUEST_CATEGORIES,
    SET_CATEGORIES,
    REQUEST_DELETE_CATEGORY,
    REQUEST_EDIT_CATEGORY,
    REQUEST_ADD_CATEGORY,
    REQUEST_SORT_CATEGORY
} from "@actions/actionTypes";


export function getCategories() {
    return dispatch => {
        dispatch({type: REQUEST_CATEGORIES});
    }
}

export function clearCategories() {
    return dispatch => {
        dispatch({type: SET_CATEGORIES});
    }
}

export function deleteCategory(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_DELETE_CATEGORY,
            data: {id},
            isOpenedByPopup: true
        });
    }
}

export function editCategory(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_CATEGORY,
            data,
            isOpenedByPopup: true
        });
    }
}


export function addCategory(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_CATEGORY,
            data,
            isOpenedByPopup: true
        });
    }
}

export function sortCategory(body) {
    return dispatch => {
        dispatch({type: REQUEST_SORT_CATEGORY, body})
    }
}

export function setCategory(body) {
    return dispatch => {
        dispatch({type: SET_CATEGORIES, categories: body})
    }
}