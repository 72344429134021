import initialStore from "../initialStore";
import {
    SET_TRANSACTIONS
} from "@actions/actionTypes";

export default function transactionsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_TRANSACTIONS:
            return action.transactions;
        default:
            return state;
    }
}
