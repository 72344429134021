import React, { useState } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import { useEffect } from "react";
import {
    getGamesSelect
} from "@actions/gamesActions";

const { Option } = Select;

function GameSelect({games, intl, getGamesSelect, value, onChange, loading, ...anyProps}) {
    const [gamesSortedByName, setGamesSortedByName] = useState([]);

    useEffect(() => {
        getGamesSelect();
    }, [])

    useEffect(() => {
        setGamesSortedByName(games.slice().sort((a, b) => {
                let aName = a.game.name.toLowerCase();
                let bName = b.game.name.toLowerCase();
                if (aName > bName) {
                    return 1;
                } else if (aName < bName) {
                   return -1;
                } else {
                    return 0;
                }
            }))
    }, [games])

    
    return (
        <Select
            dropdownClassName="games-dropdown"
            showSearch
            placeholder={intl.formatMessage({id: "select_game"})}
            optionFilterProp="children"
            value={value}
            onChange={onChange}
            {...anyProps}
            allowClear
            loading={loading._games_select}
            filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                gamesSortedByName.map(game => (<Option key={game.game.id} value={game.game.id}>{game.game.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        games: state.games
    }
}

const mapDispatchToProps = {
    getGamesSelect
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameSelect));