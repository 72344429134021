import React, {useEffect, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import PaginationLoader from "@components/Loaders/paginationLoader";
import {Table, Pagination, Space, Tooltip, Typography, Popover} from "antd";
import moment from "moment-timezone";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";
import HeaderTamplate from "@components/HeaderTamplate";
import RoundReportFilter from "@components/RoundReportFilter";
import RoundReportDetails from "@components/RoundReportDetails";
import DeleteRoundConfirmPopup from "@components/DeleteRoundConfirmPopup";
import { REQUEST_DELETE_ROUND } from "@actions/actionTypes";

import {getRoundReport, deleteRound} from "@actions/roundReportActions";
import { CopyFilled } from '@ant-design/icons';
import {
    InfoCircleFilled,
    DeleteFilled
} from "@ant-design/icons";
import {StatisticsDurationsType, TransactionType} from "../../../constants/Enums";
import "./style.sass";
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";

const {Text} = Typography;

function RoundReport({intl, pagination, loading, user, roundReport, allowCancel, setPagination, getRoundReport, deleteRound, setDoneMsg, setErrorMsg}) {

    let history = useHistory();
    const search = history && history.location && history.location.search;
    const [showFilter, setShowFilter] = useState(true);
    const [visibleDetails, setVisibleDetails] = useState({});
    const [confirmDeleteId, setConfirmDeleteId] = useState();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            const loc = history && history.location && history.location.search;
            const initialForm = getApiEventsRequestBody(loc);
            setPagination({
                page: initialForm.page || 1,
                limit: initialForm.limit || 10
            })
            getRoundReport({page: 1, limit: 10, ...initialForm, operatorId: user.profile.sub});
        }
    }, [])

    useEffect(() => {
        if (confirmDeleteId) {
            subscribeForError(REQUEST_DELETE_ROUND, () => {
                setConfirmDeleteId(null);
            }, confirmDeleteId);
        }
        
        return () => {
            unSubscribeForError(REQUEST_DELETE_ROUND);
        }
    }, [confirmDeleteId])

    const columns = [
        /*{
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            className: "copyable-column",
            render: (id, record) => {
                return  <Tooltip title={id} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {id}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, id, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        },*/
       
        {
            title: intl.formatMessage({id: "round_id"}),
            dataIndex: 'externalRoundId',
            className: "copyable-column",
            render: (roundId, record) => {
                return  <Tooltip title={roundId} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {roundId}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, roundId, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        },
        {
            title: intl.formatMessage({id: "game_id"}),
            dataIndex: 'gameId'
        },
        
        
        {
            title: intl.formatMessage({id: "start_date"}),
            dataIndex: 'startDate',
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let time = moment(record.startDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")
                return <Tooltip title={time} color={"#fff"} placement="top">
                        {time}
                    </Tooltip>
            }

        },
        {
            title: intl.formatMessage({id: "end_date"}),
            dataIndex: 'endDate',
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let time = moment(record.endDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")
                return <Tooltip title={time} color={"#fff"} placement="top">
                        {time}
                    </Tooltip>
            }

        },
        {
            title: "Round Status",
            dataIndex: 'isCanceled',
            render: (text, record) => {
                return record.isCanceled ? "Cancelled" : "Completed";
            }
        },
        /*{
            title: intl.formatMessage({id: "info"}),
            dataIndex: 'type',
            width: 80,
            render: (text, record) => {
                return <Popover overlayClassName="round-details-content"
                                placement="left"
                                title={<FormattedMessage id="details"/>}
                                trigger="click"
                                visible={visibleDetails[record.id]}
                                onVisibleChange={(isShow) => setVisibleDetails(prev => ({...prev, [record.id]: isShow}))}
                                content={<RoundReportDetails event={record} onClose={() => setVisibleDetails(prev => ({...prev, [record.id]: false}))}/>} >
                    <InfoCircleFilled />
                </Popover>
            }

        },*/
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'id',
            render: (text, record) => (
                <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                    <Popover overlayClassName="round-details-content"
                                placement="left"
                                title={<FormattedMessage id="details"/>}
                                trigger="click"
                                visible={visibleDetails[record.id]}
                                onVisibleChange={(isShow) => setVisibleDetails(prev => ({...prev, [record.id]: isShow}))}
                                content={<RoundReportDetails event={record} onClose={() => setVisibleDetails(prev => ({...prev, [record.id]: false}))}/>} >
                        <InfoCircleFilled />
                    </Popover>
                    {!record.isCanceled && (
                    <>
                    <span className="line-vertical"></span>
                    <Tooltip placement="top" title={"Cancel Round"}>
                        <DeleteFilled onClick={() => onDelete(record)}/>
                    </Tooltip>
                    </>
                    )}
                </Space>
            )
        }
        /*{
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'date',
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let time = moment(record.date).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")
                return <Tooltip title={time} color={"#fff"} placement="top">
                        {time}
                    </Tooltip>
            }

        }*/
    ];
    /*if (user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin) {
        columns.splice(1, 0, {
            title: intl.formatMessage({id: "operator_id"}),
            dataIndex: 'operatorId',
            className: "copyable-column",
            render: (operatorId, record) => {
                return  <Tooltip title={operatorId} color={"#fff"} placement="top">
                            <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                                {operatorId}
                            </Text>
                            <span className="copyable" onClick={(e) => {
                                        copyLabel(e, operatorId, setDoneMsg, setErrorMsg);
                                    }}>
                                <CopyFilled />
                            </span>
                    </Tooltip>
            }
        })
    }*/
    const onDelete = (item) => {
        console.log("show delete pop up", item)
        setConfirmDeleteId(item.id);
    }
    const onDeleteRound = (id) => {
        deleteRound(id);
    }

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }

    const exportCVS = () => {
        console.log("exportCVS click")
    }

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const loc = history && history.location && history.location.search;
        const initialForm = getApiEventsRequestBody(loc);

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()});
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            console.log("pagination api")
            getRoundReport({...initialForm, page: newPage, limit: changedLimit, operatorId: user.profile.sub});
            console.log("Sad")
        }

    }

    const cancelTransactionsCsvDownload = () => {
        console.log("cancel")
    }

    return (
        <div className="content roundReport">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='round_report'/>
                </div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <div className="roundreport" style={{maxWidth: '100%'}}>
                <div className="mainTable" style={{maxWidth: '100%'}}>
                    <HeaderTamplate className='playersreport-block'
                        title="round_report"
                        changeDuration={false}
                        lastUpdate={''}
                        size={24}>
                            {
                                <div className="operator-duration-block">
                                    <RoundReportFilter showFilter={showFilter}/>
                                    {loading._round_report === undefined || loading._round_report ?
                                    <TableLoader count={pagination.limit} column={5} icon={false} drag={false} actions={0} pagination={false}/> :
                                    <Table dataSource={roundReport}
                                        columns={columns}
                                        className="event-table"
                                        rowKey="id"
                                        scroll={{x: 1000}}
                                        pagination={false}
                                    />}
                                </div>
                            }
                    </HeaderTamplate>
                    <Space direction="horizontal" className="bottom-bar">
                        <CsvDownloader exportCVS={exportCVS}
                                    titleDownload="load_events"
                                    downloadLoading={loading._round_report_csv_downloader_url}
                                    cancelLoading={loading._cancel_round_report_csv_downloader_url}
                                    onCancel={cancelTransactionsCsvDownload}
                                    allowCancel={allowCancel}/>
                        {
                            loading._round_report === undefined || loading._round_report ?
                            <PaginationLoader /> :
                            <Pagination defaultCurrent={pagination.page}
                                className="ant-table-pagination ant-table-pagination-right"
                                total={pagination.total} 
                                showSizeChanger={true}
                                showLessItems={true}
                                loading={loading._round_report === undefined || loading._round_report}
                                onChange={(page, limit) => handleTablePaginate(page, limit)}
                                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                                defaultPageSize={pagination.limit}/>
                        }
                        
                    </Space>
                </div>
            </div>
            <DeleteRoundConfirmPopup onConfirm={() => {
                                        onDeleteRound(confirmDeleteId);
                                    }}
                                    onCancel={() => setConfirmDeleteId(null)}
                                    loading={loading["_delete_round" + confirmDeleteId]}
                                    show={confirmDeleteId} />
           
        </div>
    )
}

function mapStateToProps(state) {
    return {
        roundReport: state.roundReport,
        loading: state.loading,
        pagination: state.pagination,
        allowCancel: state.csvAllowCancel.allowTransactions,
        user: state.user
    }
}

const mapDispatchToProps = {
    getRoundReport,
    deleteRound,
    setPagination,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RoundReport));