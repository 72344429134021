import {
    REQUEST_ASSIGNMENT_GAMES,
    CLEAR_ASSIGNMENT_GAMES,
    REQUEST_EDIT_ASSIGNMENT_GAME,
    REQUEST_ADD_ASSIGNMENT_GAME,
    REQUEST_DELETE_ASSIGNMENT_GAME
} from "@actions/actionTypes";


export function getGamesAssignment(data) {
    return dispatch => {
        dispatch({type: REQUEST_ASSIGNMENT_GAMES, data});
    }
}

export function createGamesAssignment(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_ASSIGNMENT_GAME,
            data,
            isOpenedByPopup: true
        });
    }
}


export function editGamesAssignment(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_ASSIGNMENT_GAME,
            data,
            isOpenedByPopup: true
        });
    }
}

export function clearGamesAssignment() {
    return dispatch => {
        dispatch({type: CLEAR_ASSIGNMENT_GAMES});
    }
}

export function deleteGamesAssignment(data) {
    return dispatch => {
        dispatch({type:  REQUEST_DELETE_ASSIGNMENT_GAME, data});
    }
}