import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import PaginationLoader from "@components/Loaders/paginationLoader";
import GamesLoader from "@components/Loaders/gamesLoader";
import AddEditGameAssignmentModal from "@components/AddEditGameAssignmentModal";
import AddEditGameModal from "@components/AddEditGameModal";
import GameDeleteConfirmPopup from "@components/GameDeleteConfirmPopup";
import NoData from "@components/NoData";
import GameItem from "@components/GameItem";
import {Table, Input, Space, Pagination} from "antd";
import moment from "moment-timezone";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {
    getGames,
    clearGames,
    deleteGame
} from "@actions/gamesActions";
import {setPagination} from "@actions/paginationsActions";

import {} from "@actions/gamesActions";
import {
    
} from "@ant-design/icons";
import "./style.sass";

function Games({intl, pagination, loading, user, games, getGames, clearGames, setPagination, deleteGame}) {

    let history = useHistory()
    const search = history && history.location && history.location.search
    const [gameControlPopup, setGameControlPopup] = useState('');
    const [gameControlPopupAdmin, setGameControlPopupAdmin] = useState('');
    const [singleGame, setSingleGame] = useState('');
    const [singleGameForAdmin, setSingleGameForAdmin] = useState('');
    const [confirmDeleteId, setConfirmDeleteId] = useState();

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchGames(true)

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])
 

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }


    const fetchGames = (isFirst) => {
        let initialForm = getApiEventsRequestBody()
        initialForm = {
            ...initialForm,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            getGames(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getApiEventsRequestBody(loc)
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: Math.max(10, (+initialForm.limit || pagination.limit)),
                })
                getGames(initialForm)
            }
        })
    },[history])

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const initialForm = getApiEventsRequestBody()

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})

    }

    const onEditGame = (game) => {
        let newObj = {...game, internalSettings: JSON.parse(game.internalSettings), settings: JSON.parse(game.settings)}
        setSingleGameForAdmin(newObj);
        setGameControlPopupAdmin("edit")
    }

    const onDeleteGame = (game) => {
        deleteGame(game.id);
    }

    return (
        <div className="content games-block">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='games'/>
                </div>
                {
                    user?.IsAdmin?
                    <div className="titleArea--BtnGroup">
                        <button className="mainBtn"
                                style={{border: 'unset'}}
                                onClick={() => setGameControlPopupAdmin("add")}>
                            <div className="icon"/>
                            <FormattedMessage id='add_game'/>
                        </button>
                    </div> : ''
                }
                
            </div>
            <div className="games">
                <Space direction="vertical" className="game-list" size={[0, 24]}>
                    <div className="title">
                            <FormattedMessage id='games_list'/>
                    </div>
                    {
                        loading._games === undefined || loading._games ?
                        <GamesLoader /> :
                        games.length == 0?
                        <NoData /> :
                        <div>
                            {
                                games.map((game, i) => (
                                    <GameItem item={game.game}
                                        key={i}
                                        isEditable={user?.IsAdmin || user?.IsSuperAdmin}
                                        onEdit={onEditGame}
                                        onDelete={setConfirmDeleteId}
                                        />
                                ))
                            }
                        </div>
                    }
                </Space>
                {
                    loading._games === undefined || loading._games ?
                    <PaginationLoader /> :
                    <Pagination defaultCurrent={pagination.page}
                        className="ant-table-pagination ant-table-pagination-right"
                        total={pagination.total} 
                        showSizeChanger={true}
                        showLessItems={true}
                        loading={loading._games === undefined || loading._games}
                        onChange={(page, limit) => handleTablePaginate(page, limit)}
                        showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                        defaultPageSize={pagination.limit}/>
                }
                        
            </div>
            {
                gameControlPopup? <AddEditGameAssignmentModal handleCancel={
                                                    () => {
                                                        setGameControlPopup("");
                                                        setSingleGame({})
                                                    }
                                                }
                                                show={gameControlPopup}
                                                game={singleGame}/> : ""
            }
            
            { gameControlPopupAdmin? <AddEditGameModal handleCancel={
                                                () => {
                                                    setSingleGameForAdmin({})
                                                    setGameControlPopupAdmin("")
                                                }
                                            }
                                            show={gameControlPopupAdmin}
                                            game={singleGameForAdmin}/> : ""
            }
            
            <GameDeleteConfirmPopup onConfirm={() => {
                                        onDeleteGame(confirmDeleteId);
                                        setConfirmDeleteId(null);
                                    }}
                                    onCancel={() => setConfirmDeleteId(null)}
                                    loading={loading._delete_game}
                                    show={confirmDeleteId} />
            
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        pagination: state.pagination,
        games: state.games,
        user: state.user?.profile
    }
}

const mapDispatchToProps = {
    getGames,
    clearGames,
    deleteGame,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Games));