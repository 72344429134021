import React from "react";
import {
    Menu, Space
} from "antd";
import {
    StarFilled
} from "@ant-design/icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserEmptyImg from "@assets/img/userEmptyImg.svg";

export default function List({users, onSelectUser, selecktedKeys, search, ...resProps}) {
    const handleMenuClick = (item) => {
        onSelectUser(item);
    }
    const setSearchedCollor = (name) => {
        if (search.length === 0) {
            return name;
        }

        const output = [];
        let index = 0;

        do {
            const phraseIndex = name.toLowerCase().indexOf(search.toLowerCase(), index);
      
            if (phraseIndex === -1) {
              const remainder = name.substr(index);
              output.push(remainder);
              break;
            }
      
            const nameUntilMatch = name.substring(index, phraseIndex);
            index = phraseIndex + search.length;
            const searchedValue = name.substring(phraseIndex, index);
      
            output.push(nameUntilMatch);
            output.push(<span className="search-sub-string" key={phraseIndex}>{searchedValue}</span>);
            
          } while (index < name.length);

        return output
    }

    return (
        <div {...resProps}>
            <Menu onSelect={handleMenuClick}
                  selectedKeys={selecktedKeys}
                  defaultSelectedKeys={selecktedKeys}>
                {
                    users.filter(u => u.name.toLowerCase().indexOf(search.toLowerCase()) != -1).map(user => (
                        <Menu.Item  key={user.id} icon={
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            src={user.image || UserEmptyImg} />
                                                        }>
                            <Space direction="horizontal" size={[8, 0]}>
                                <span>
                                    {setSearchedCollor(user.name)}
                                </span>
                                <StarFilled />
                            </Space>
                        </Menu.Item>
                    ))
                }
            </Menu>
        </div>
    )
}