export const FETCH_FAILED = "FETCH_FAILED";

export const USER_LOGGING_REQUEST = "USER_LOGGING_REQUEST";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_END_SILENT = "SET_IS_END_SILENT";

export const SET_RESEND_FORGOT_PASSWORD = "SET_RESEND_FORGOT_PASSWORD";

export const REQUEST_START_PATH = "REQUEST_START_PATH";
export const SET_START_PATH = "SET_START_PATH";


//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

//loading
export const CHANGE_LOADING = 'CHANGE_LOADING';

//pagination
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const UPDATE_BY_START_PAGINATION = 'UPDATE_BY_START_PAGINATION';
export const UPDATE_PAGINATION_TOTAL = 'UPDATE_PAGINATION_TOTAL';

export const SET_LANG_LIST = "SET_LANG_LIST"

// csv
export const SET_ALLOW_CSV_CANCEL = "SET_ALLOW_CSV_CANCEL";

// dashboard
export const REQUEST_OPERATOR_API_EVENTS = "REQUEST_OPERATOR_API_EVENTS";
export const SET_OPERATOR_API_EVENTS = "SET_OPERATOR_API_EVENTS";

//games
export const REQUEST_GAMES = "REQUEST_GAMES";
export const SET_GAMES = "SET_GAMES";
export const REQUEST_EDIT_GAME = "REQUEST_EDIT_GAME"
export const SET_EDIT_GAME = "SET_EDIT_GAME"
export const REQUEST_ADD_GAME = "REQUEST_ADD_GAME"
export const SET_ADD_GAME = "SET_ADD_GAME"
export const CLEAR_GAMES = "CLEAR_GAMES"
export const REQUEST_DELETE_GAME = "REQUEST_DELETE_GAME"
export const SET_DELETE_GAME = "SET_DELETE_GAME"
export const REQUEST_GAMES_SELECT = "REQUEST_GAMES_SELECT"
export const SET_GAMES_SELECT = "SET_GAMES_SELECT"
export const REQUEST_GAMES_SELECT_ALL = "REQUEST_GAMES_SELECT_ALL"
export const SET_GAMES_SELECT_ALL = "SET_GAMES_SELECT_ALL"

//games assignment
export const REQUEST_ASSIGNMENT_GAMES = "REQUEST_ASSIGNMENT_GAMES";
export const SET_ASSIGNMENT_GAMES = "SET_ASSIGNMENT_GAMES";
export const REQUEST_EDIT_ASSIGNMENT_GAME = "REQUEST_EDIT_ASSIGNMENT_GAME"
export const SET_EDIT_ASSIGNMENT_GAME = "SET_EDIT_ASSIGNMENT_GAME"
export const REQUEST_ADD_ASSIGNMENT_GAME = "REQUEST_ADD_ASSIGNMENT_GAME"
export const SET_ADD_ASSIGNMENT_GAME = "SET_ADD_ASSIGNMENT_GAME"
export const REQUEST_DELETE_ASSIGNMENT_GAME = "REQUEST_DELETE_ASSIGNMENT_GAME"
export const SET_DELETE_ASSIGNMENT_GAME = "SET_DELETE_ASSIGNMENT_GAME"
export const CLEAR_ASSIGNMENT_GAMES = "CLEAR_ASSIGNMENT_GAMES"

//operator api key
export const REQUEST_OPERATOR_API_KEYS = "REQUEST_OPERATOR_API_KEYS";
export const SET_OPERATOR_API_KEYS = "SET_OPERATOR_API_KEYS";

//edit operator api key
export const REQUEST_ADD_OPERATOR_API_KEY = "REQUEST_ADD_OPERATOR_API_KEY";
export const SET_ADD_OPERATOR_API_KEY = "SET_ADD_OPERATOR_API_KEY";
export const REQUEST_EDIT_OPERATOR_API_KEY = "REQUEST_EDIT_OPERATOR_API_KEY";
export const SET_EDIT_OPERATOR_API_KEY = "SET_EDIT_OPERATOR_API_KEY";
export const REQUEST_DELETE_OPERATOR_API_KEY = "REQUEST_DELETE_OPERATOR_API_KEY";
export const SET_DELETE_OPERATOR_API_KEY = "SET_DELETE_OPERATOR_API_KEY";
export const CLEAR_OPERATOR_API_KEYS = "CLEAR_OPERATOR_API_KEYS";

//notification
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

//users
export const SET_USERS = "SET_USERS";
export const REQUEST_USERS = "REQUEST_USERS";
export const CLEAR_USERS = "CLEAR_USERS";

//currency
export const SET_CURRENCY = "SET_CURRENCY";
export const REQUEST_CURRENCY = "REQUEST_CURRENCY";
export const CLEAR_CURRENCY = "CLEAR_CURRENCY";

//countries
export const SET_COUNTRIES = "SET_COUNTRIES";
export const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
export const CLEAR_COUNTRIES = "CLEAR_COUNTRIES";

//transactions
export const REQUEST_TRANSACTIONS = "REQUEST_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";

//category
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const REQUEST_ADD_CATEGORY = "REQUEST_ADD_CATEGORY"; 
export const SET_ADD_CATEGORY = "SET_ADD_CATEGORY"; 
export const REQUEST_EDIT_CATEGORY = "REQUEST_EDIT_CATEGORY"; 
export const SET_EDIT_CATEGORY = "SET_EDIT_CATEGORY"; 
export const REQUEST_DELETE_CATEGORY = "REQUEST_DELETE_CATEGORY"; 
export const SET_DELETE_CATEGORY = "SET_DELETE_CATEGORY";
export const REQUEST_SORT_CATEGORY = "REQUEST_SORT_CATEGORY";

//dealers
export const REQUEST_DEALERS = "REQUEST_DEALERS";
export const SET_DEALERS = "SET_DEALERS";
export const REQUEST_ADD_DEALER = "REQUEST_ADD_DEALER";
export const SET_ADD_DEALER = "SET_ADD_DEALER";
export const REQUEST_EDIT_DEALER = "REQUEST_EDIT_DEALER";
export const SET_EDIT_DEALER = "SET_EDIT_DEALER";
export const REQUEST_DELETE_DEALER = "REQUEST_DELETE_DEALER";
export const SET_DELETE_DEALER = "SET_DELETE_DEALER";
export const CLEAR_DEALERS = "CLEAR_DEALERS";

//games report
export const REQUEST_GAMES_REPORT = "REQUEST_GAMES_REPORT";
export const SET_GAMES_REPORT = "SET_GAMES_REPORT";

//players report
export const REQUEST_PLAYERS_REPORT = "REQUEST_PLAYERS_REPORT";
export const SET_PLAYERS_REPORT = "SET_PLAYERS_REPORT";

//round report
export const REQUEST_ROUND_REPORT = "REQUEST_ROUND_REPORT";
export const SET_ROUND_REPORT = "SET_ROUND_REPORT";
export const REQUEST_DELETE_ROUND = "REQUEST_DELETE_ROUND"; 
export const SET_DELETE_ROUND = "SET_DELETE_ROUND";