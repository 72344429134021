import initialStore from "../initialStore";
import { SET_OPERATOR_API_KEYS, SET_EDIT_OPERATOR_API_KEY } from "@actions/actionTypes";

export default function operatorApiKeysReducer(state = initialStore.operatorApiKeys, action) {
    switch (action.type) {
        case SET_OPERATOR_API_KEYS:
            return action.operatorApiKeys;
        case SET_EDIT_OPERATOR_API_KEY:
            return state.map((item) => {
                if (item.id == action.data.id) {
                    return {
                        ...item,
                        ...action.data
                    };
                }
                return item;
            });
        default:
            return state;
    }
}