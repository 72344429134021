import React, { useState } from "react";
import {FormattedMessage} from "react-intl";
import {CopyFilled, CloseOutlined} from '@ant-design/icons';
import {Typography} from 'antd';

export default function OperatorEventDetailsbody({event, onClose}) {

    const { Paragraph } = Typography;

    return (
        <>
        
        <div className="operator-details">
            <div>
                <span>
                    <FormattedMessage id="game_id"/>:
                </span>
                <span>
                    {/* <Paragraph copyable={{
                                icon: [<CopyFilled />]
                            }}>
                        
                    </Paragraph> */}
                    {event.gameId}
                </span>
            </div>
            <div className="top-bottom-border">
                <span>
                    <FormattedMessage id="operator_id"/>:
                </span>
                <span>
                    {event.operatorId}
                </span>
            </div>
            <div>
                <span>
                    <FormattedMessage id="operator_name"/>:
                </span>
                <span>
                    {event.operatorName}
                </span>
            </div>
            <div >
                <span>
                    <FormattedMessage id="user_name"/>:
                </span>
                <span>
                    {event.userName}
                </span>
            </div>
        </div>
        <CloseOutlined className="close-button" onClick={onClose}/>
        </>
    )
}