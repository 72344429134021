import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_PLAYERS_REPORT,
    SET_PLAYERS_REPORT,
    UPDATE_PAGINATION_TOTAL
} from "@actions/actionTypes";
import {GetResource} from "../../utils/HttpUtil";
import {getQuery} from "../../utils/CommonUtils"; 
import {safe, onError} from "./handlerError";


function* fetchPlayersReport({data}) {
    const query = getQuery({...data, SortBy: 'CreateDate', SortOrder: 'Desc'});
    const response =  yield call(GetResource, `/Reports/players-report?${query}`);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: response.data.totalCount});
    yield put({type: SET_PLAYERS_REPORT, playersReport: response.data.playerReports});
}

function* playersReportSaga() {
    yield takeLatest(REQUEST_PLAYERS_REPORT, safe(onError, fetchPlayersReport));
}

export default playersReportSaga;