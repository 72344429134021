import React from "react";
import {Modal, Form, Button, Space} from "antd";
import {FormattedMessage} from "react-intl";
import confirmIcon from "@assets/img/icon/confirm-icon.svg";

export default function GameDeleteConfirmPopup({onConfirm, onCancel, show, loading}) {

    return (
        <Modal
            title={false}
            visible={show}
            footer={false}
            width={434}
            className="delete-confirm-popup"
        >
            <Space direction="vertical" style={{width: "100%"}}>
                <Space direction="vertical" className="body" size={[0, 24]}>
                    <img src={confirmIcon} className="delete-confirm-img" />
                    <h3><FormattedMessage id='delete_game'/></h3>
                    <p><FormattedMessage id='delete_game_confirm_popup_text'/></p>
                </Space>
                <Form.Item className="MainModal--footer">
                    <button type="reset" className="miniBtn default" onClick={onCancel}>
                        <FormattedMessage id='cancel'/>
                    </button>
                    <Button style={{height: 36}}
                            className="miniBtn submit"
                            loading={loading}
                            onClick={onConfirm}
                    >
                        <FormattedMessage id='delete'/>
                    </Button>
                </Form.Item>
            </Space>
      </Modal>
      )
}