import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ROUND_REPORT,
    SET_ROUND_REPORT,
    UPDATE_PAGINATION_TOTAL,
    REQUEST_DELETE_ROUND,
    SET_DELETE_ROUND
} from "@actions/actionTypes";
import {GetResource, PostResource} from "../../utils/HttpUtil";
import {getQuery} from "../../utils/CommonUtils"; 
import {safe, onError} from "./handlerError";


function* fetchRoundReport({data}) {
    console.log("calling api..")
    const query = getQuery({...data, SortBy: 'CreateDate', SortOrder: 'Desc'});
    const response =  yield call(GetResource, `/Round?${query}`);
    if(response.data) {
        yield put({type: UPDATE_PAGINATION_TOTAL, total: response.totalCount});
        yield put({type: SET_ROUND_REPORT, roundReport: response.data});
    }
}

function* deleteRound({data}) {
    yield call(PostResource, `/Round/cancel/${data.id}`, data);
    yield put({type: SET_DELETE_ROUND, data});
    yield put({
        type: REQUEST_ROUND_REPORT
    })
}

function* roundReportSaga() {
    yield takeLatest(REQUEST_ROUND_REPORT, safe(onError, fetchRoundReport));
    yield takeLatest(REQUEST_DELETE_ROUND, safe(onError, deleteRound));
}

export default roundReportSaga;