import initialStore from "../initialStore";
import { SET_NOTIFICATIONS, CLEAR_NOTIFICATIONS } from "@actions/actionTypes";

export default function notificationReducer(state = initialStore.notifications, action) {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return action.notifications;
        default:
            return state;
    }
}
