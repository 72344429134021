import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_GAMES_REPORT,
    SET_GAMES_REPORT,
    UPDATE_PAGINATION_TOTAL
} from "@actions/actionTypes";
import {GetResource} from "../../utils/HttpUtil";
import {getQuery} from "../../utils/CommonUtils"; 
import {safe, onError} from "./handlerError";


function* fetchGamesReport({data}) {
    const query = getQuery({...data, SortBy: 'CreateDate', SortOrder: 'Desc'});
    const response =  yield call(GetResource, `/Reports/games-report?${query}`);
   
    yield put({type: UPDATE_PAGINATION_TOTAL, total: response.data.totalCount});
    yield put({type: SET_GAMES_REPORT, gamesReport: response.data.gameReports});
}

function* gamesReportSaga() {
    yield takeLatest(REQUEST_GAMES_REPORT, safe(onError, fetchGamesReport));
}

export default gamesReportSaga;