import initialStore from "../initialStore";
import { SET_CATEGORIES, CLEAR_CATEGORIES} from "@actions/actionTypes";

export default function categoriesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_CATEGORIES:
            return action.categories;
        case CLEAR_CATEGORIES:
            return initialStore.emptyArr;
        default:
            return state;
    }
}