import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import PaginationLoader from "@components/Loaders/paginationLoader";
import {Table, Pagination, Space, Menu, Dropdown, Popover} from "antd";
import moment from "moment-timezone";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";
import HeaderTamplate from "@components/HeaderTamplate";
import OperatorEventDetailsbody from "@components/OperatorEventDetailsbody";
import OperatorApiEventsFilter from "@components/OperatorApiEventsFilter";

import {getOperarorApiEvents} from "@actions/dashboardActions";
import {
    InfoCircleFilled
} from "@ant-design/icons";
import {StatisticsDurationsType, EventType} from "../../../constants/Enums";
import "./style.sass";

function Dashboard({intl, pagination, loading, user, operatorApiEvents, allowCancel, setPagination, getOperarorApiEvents}) {

    let history = useHistory();
    const search = history && history.location && history.location.search;
    const [visibleDetails, setVisibleDetails] = useState({});

    useEffect(() => {
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            const loc = history && history.location && history.location.search;
            const initialForm = getApiEventsRequestBody(loc);
            setPagination({
                page: initialForm.page || 1,
                limit: initialForm.limit || 10
            })
            getOperarorApiEvents({page: 1, limit: 10, ...initialForm, operatorId: user.profile.sub});
        }
    }, [])

    const columns = [
        {
            title: intl.formatMessage({id: "user_name"}),
            dataIndex: 'userName',
        },
        {
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
        },
        {
            title: intl.formatMessage({id: "type"}),
            dataIndex: 'type',
            render: (type) => {
                return (
                    <span className={classNames("event-type", EventType[type])}>
                        <FormattedMessage id={EventType[type]} />
                    </span>
                )
            }
        },
        {
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'createDate',
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <span>{moment(record.createDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</span>
            }

        },
        {
            title: intl.formatMessage({id: "info"}),
            dataIndex: 'type',
            width: 80,
            render: (text, record) => {
                return <Popover overlayClassName="operator-details-content"
                                placement="left"
                                title={<FormattedMessage id="details"/>}
                                trigger="click"
                                visible={visibleDetails[record.id]}
                                onVisibleChange={(isShow) => setVisibleDetails(prev => ({...prev, [record.id]: isShow}))}
                                content={<OperatorEventDetailsbody event={record} onClose={() => setVisibleDetails(prev => ({...prev, [record.id]: false}))}/>} >
                    <InfoCircleFilled />
                </Popover>
            }

        }
    ];

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }

    const exportCVS = () => {
        console.log("exportCVS click")
    }

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const loc = history && history.location && history.location.search;
        const initialForm = getApiEventsRequestBody(loc);

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()});
        if (user?.profile && !(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)) {
            getOperarorApiEvents({...initialForm, page: newPage, limit: changedLimit, operatorId: user.profile.sub});
        }

    }

    const cancelOperatorApiEventsCsvDownload = () => {
        console.log("cancel")
    }

    return (
        <div className="content transactions">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='dashboard'/>
                </div>
            </div>
            <div className="dashboard">
                <div className="mainTable">
                    <HeaderTamplate className='dashboard-block'
                        title="operator_api_vents"
                        changeDuration={false}
                        lastUpdate={''}
                        size={24}>
                            {
                                user?.profile?.IsAdmin || user?.profile.IsSuperAdmin? <div className="operator-duration-block">
                                    <OperatorApiEventsFilter />
                                    {loading._operator_api_events === undefined || loading._operator_api_events ?
                                    <TableLoader count={pagination.limit} column={5} icon={false} drag={false} actions={0} pagination={false}/> :
                                    <Table dataSource={operatorApiEvents}
                                        columns={columns}
                                        className="event-table"
                                        rowKey="id"
                                        scroll={{x: '100%'}}
                                        pagination={false}
                                    />}
                                </div>
                                :
                                <>
                                    {loading._operator_api_events === undefined || loading._operator_api_events ?
                                    <TableLoader count={pagination.limit} column={8} icon={false} drag={false} actions={0} pagination={false}/> :
                                    <Table dataSource={operatorApiEvents}
                                        columns={columns}
                                        rowKey="id"
                                        scroll={{x: '100%'}}
                                        pagination={false}
                                    />}
                                </>
                            }
                        
                        
                    </HeaderTamplate>
                    <Space direction="horizontal" className="bottom-bar">
                        <CsvDownloader exportCVS={exportCVS}
                                    titleDownload="load_events"
                                    downloadLoading={loading._operator_api_events_csv_downloader_url}
                                    cancelLoading={loading._cancel_operator_api_events_csv_downloader_url}
                                    onCancel={cancelOperatorApiEventsCsvDownload}
                                    allowCancel={allowCancel}/>
                        {
                            loading._operator_api_events === undefined || loading._operator_api_events ?
                            <PaginationLoader /> :
                            <Pagination defaultCurrent={pagination.page}
                                className="ant-table-pagination ant-table-pagination-right"
                                total={pagination.total} 
                                showSizeChanger={true}
                                showLessItems={true}
                                loading={loading._operator_api_events === undefined || loading._operator_api_events}
                                onChange={(page, limit) => handleTablePaginate(page, limit)}
                                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                                defaultPageSize={pagination.limit}/>
                        }
                        
                    </Space>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operatorApiEvents: state.operatorApiEvents,
        loading: state.loading,
        pagination: state.pagination,
        allowCancel: state.csvAllowCancel.allowDeposits,
        user: state.user
    }
}

const mapDispatchToProps = {
    getOperarorApiEvents,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));