import React, {useEffect, useContext, useState, useRef } from "react";
import {connect} from "react-redux";
import {
    addGame,
    editGame
} from "@actions/gamesActions";
import {
    REQUEST_ADD_GAME,
    REQUEST_EDIT_GAME
} from "@actions/actionTypes";

import {Modal, Form, Button, Input, Space, Row, Col} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ImgUpload from "@components/ImgUpload/ImgUpload";
import CategoriesSelect from "@components/CategoriesSelect";
import { RedoOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {copyLabel} from "../../utils/CommonUtils";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";

function AddEditGameModal({handleCancel, show, intl, game, loading, addGame, editGame, setDoneMsg, setErrorMsg}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);
    const imageUploadRef = useRef();

    const deleteImage = () => {
        form.setFieldsValue({image: ''});
    }

    const resetUploadImage = () => {
        form.setFieldsValue({image: game?.image || ""});
        chackIsValid();
    }

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    const formChange = (d) => {
        chackIsValid();
    }

    useEffect(() => {
        if (game.id) {
            subscribeForError(REQUEST_EDIT_GAME, () => {
                handleCancel();
            }, game.id);
        } else {
            subscribeForError(REQUEST_ADD_GAME, () => {
                handleCancel();
            });
        }
        // form.setFieldsValue({})
        return () => {
            unSubscribeForError(REQUEST_ADD_GAME);
            unSubscribeForError(REQUEST_EDIT_GAME);
        }
    }, [])

    useEffect(() => {
        if(game.id) {
            var newObj = {...game};
            form.setFieldsValue(newObj);
        }
    }, [game])

    const onFinish = (data) => {
        var newObj = {...data};
        newObj.settings = JSON.stringify(newObj.gameSettings);
        newObj.internalSettings = JSON.stringify(newObj.gameInternalSettings);
        if (show === "add") {
            addGame(newObj);
        } else {
            editGame({...newObj, id: game.id});
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_game'/> :
                        <FormattedMessage id='edit_game'/>}
                    centered
                    className={`formModal games-modal`}
                    visible={show}
                    width={856}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{gameSettings: game.settings || {}, gameInternalSettings: game.internalSettings || {}}}
                        name="game-form"
                        onFinish={onFinish}
                        onValuesChange={formChange}
                        onFieldsChange={formChange}
                        scrollToFirstError
                        onFinishFailed={() => chackIsValid()}
                        validateTrigger="onBlur"
                        >
                        <Space direction="horizontal" className="game-form-body top-block">
                            <Space direction="vertical" size={[0, 5]} className="top-block-body">
                                <label >
                                    <FormattedMessage id='preview'/>
                                </label>
                                <Space direction="horizontal" size={[24, 0]} className="img-block">
                                    <Form.Item name="image"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='image_is_required'/>
                                                    }
                                                ]}
                                                label={false}
                                                className="gameImgUpload"
                                                >
                                        <ImgUpload icon={<PlusOutlined />}
                                                    isOnlyImg={true}
                                                    ref={imageUploadRef}
                                                    uploadText={<FormattedMessage id='click_the_plus_icon_to_upload_new_file'/>} />
                                    </Form.Item>
                                    <Space direction="vertical" size={[0, 12]}>
                                        <Space direction="horizontal" className={classname("image-buttons", {add: show === "add"})}>
                                            
                                            <Button icon={<RedoOutlined />}
                                                    onClick={resetUploadImage}
                                                    type="text">
                                                <FormattedMessage id='reset'/>
                                            </Button>
                                            <Button icon={<DeleteOutlined />}
                                                    onClick={deleteImage}
                                                    type="text">
                                                <FormattedMessage id='delete'/>
                                            </Button>
                                        </Space>
                                        <p className="image-types">
                                            <FormattedMessage id="allowed_file_types_image" />
                                        </p>
                                    </Space>
                                </Space>
                            </Space>
                            <div className="right-block">
                                {
                                    show === "add" ? "" :
                                        <Form.Item label={<FormattedMessage id='id'/>}
                                                   name="name">
                                            <div className="copyable" onDoubleClick={(e) => copyLabel(e, form.getFieldValue("id"), setDoneMsg, setErrorMsg)}>{form.getFieldValue("id")}</div>
                                        </Form.Item>
                                }

                                <Form.Item label={<FormattedMessage id='name'/>}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id='name_validator'/>
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                let val = value
                                                if (value) {
                                                    val = value.replace(/\s/g, '');
                                                }
                                                if (value && !(val.length >= 3 && value.length <= 256)) {
                                                    return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                }

                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                    name="name">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_name"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='url'/>}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id='url_validator'/>
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                if (value && !urlPattern.test(value)) {
                                                    return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                    name="url">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_url"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='ui_url'/>}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id='front_url_validator'/>
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                if (value && !urlPattern.test(value)) {
                                                    return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                    name="frontUrl">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_front_url"})}/>
                                </Form.Item>
                            </div>
                        </Space>
                        <Row gutter={5} justify="space-between" wrap={false}>
                            <Col span={6}>
                                <Form.Item label={<FormattedMessage id='stream_name'/>}
                                           rules={[
                                               {
                                                   required: false,
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const nameRegexp = new RegExp(/^\S*$/)
                                                       if (value && !value.match(nameRegexp)) {
                                                           return Promise.reject(intl.formatMessage({id: `in_stream_name_spaces_are_not_allowed`}))
                                                       }
                                                       if (value && !(value.length >= 3 && value.length <= 50)) {
                                                           return Promise.reject(intl.formatMessage({id: `stream_name_valid`}))
                                                       }

                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name="streamName">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_stream_name"})}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<FormattedMessage id='stream_url'/>}
                                           rules={[
                                               {
                                                   required: false,
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                       if (value && !urlPattern.test(value)) {
                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name="streamUrl">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_stream_url"})}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<FormattedMessage id='stream_tag'/>}
                                           rules={[
                                               {
                                                   required: false,
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const nameRegexp = new RegExp(/^\S*$/)
                                                       if (value && !value.match(nameRegexp)) {
                                                           return Promise.reject(intl.formatMessage({id: `in_stream_tag_spaces_are_not_allowed`}))
                                                       }

                                                       if (value && !(value.length >= 3 && value.length <= 50)) {
                                                           return Promise.reject(intl.formatMessage({id: `stream_tag_valid`}))
                                                       }

                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name="streamTag">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_stream_tag"})}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<FormattedMessage id='stream_key'/>}
                                           rules={[
                                               {
                                                   required: false,
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const nameRegexp = new RegExp(/^\S*$/)
                                                       if (value && !value.match(nameRegexp)) {
                                                           return Promise.reject(intl.formatMessage({id: `in_stream_key_spaces_are_not_allowed`}))
                                                       }

                                                       if (value && !(value.length >= 3 && value.length <= 256)) {
                                                           return Promise.reject(intl.formatMessage({id: `stream_key_valid`}))
                                                       }

                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name="streamKey">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_stream_key"})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label={<FormattedMessage id='game_id'/>}
                                   rules={[
                                       {
                                           required: true,
                                           message: <FormattedMessage id='game_id_validator'/>
                                       },
                                       ({getFieldValue}) => ({
                                           validator(_, value) {
                                               const nameRegexp = new RegExp(/^\S*$/)
                                               if (value && !value.match(nameRegexp)) {
                                                   return Promise.reject(intl.formatMessage({id: `in_game_id_spaces_are_not_allowed`}))
                                               }

                                               if (value && !(value.length >= 3 && value.length <= 50)) {
                                                   return Promise.reject(intl.formatMessage({id: `game_id_valid`}))
                                               }

                                               return Promise.resolve();
                                           },
                                       })
                                   ]}
                                   name="gameId">
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_game_id"})}/>
                        </Form.Item>
                        <Form.Item name="categoryId"
                                    label={<FormattedMessage id='category' />}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: <FormattedMessage id='category_is_required'/>
                                    //     },
                                    // ]}
                                    onBlur={() => chackIsValid()}>
                                    <CategoriesSelect />
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='game_settings'/>}
                                    name="gameSettings">
                            <Editor mode={Editor.modes.tree}
                                    history/>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='game_settings'/>}
                                    name="gameInternalSettings">
                            <Editor mode={Editor.modes.tree}
                                    history/>
                        </Form.Item>
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: fildeError })}
                                    loading={loading._add_game || loading['_edit_game' + game.id]}
                                    disabled={fildeError}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    {/* <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span> */}
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    addGame,
    editGame,
    setErrorMsg,
    setDoneMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddEditGameModal));