import initialStore from "../initialStore";
import {
    SET_ROUND_REPORT
} from "@actions/actionTypes";

export default function roundReportReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {

        case SET_ROUND_REPORT:
            return action.roundReport;
        default:
            return state;
    }
}
