import React, { useState } from "react";
import {FormattedMessage} from "react-intl";
import {CopyFilled, CloseOutlined} from '@ant-design/icons';
import {Typography} from 'antd';

export default function OperatorDetailsBody({operator, onClose}) {

    const { Paragraph } = Typography;

    return (
        <>
        
        <div className="operator-details">
            <div>
                <span>
                    <FormattedMessage id="username"/>:
                </span>
                <span>
                    <Paragraph copyable={{
                                icon: [<CopyFilled />]
                            }}>
                        {operator.name}
                    </Paragraph>
                </span>
            </div>
            <div className="top-bottom-border">
                <span>
                    <FormattedMessage id="email"/>:
                </span>
                <span>
                    <Paragraph copyable={{
                              icon: [<CopyFilled />]
                          }}>
                      {operator.email}
                    </Paragraph>
                </span>
            </div>
            <div>
                <span>
                    <FormattedMessage id="operator_id"/>:
                </span>
                <span>
                    <Paragraph copyable={{
                              icon: [<CopyFilled />]
                          }}>
                      {operator.id}
                    </Paragraph>
                </span>
            </div>
        </div>
        <CloseOutlined className="close-button" onClick={onClose}/>
        </>
    )
}