import React from "react"
import { Table } from "antd"; 
import classNames from "classnames";

export default function CustomTable ({
    actionsColumn,
    tableColumn,
    className,
    rowKey,
    dataSource,
    tableScrollX,
    actionsScrollX,
    tableScrollY,
    actionsScrollY,
    components
}) {

    return (
        <div  className={classNames('custom-table', className)}>
            <Table dataSource={dataSource}
                columns={tableColumn}
                className={classNames(className, 'data-table')}
                rowKey={rowKey || "id"}
                scroll={{x: tableScrollX, y: tableScrollY}}
                pagination={false}
                components={components}
            />
             <Table dataSource={dataSource}
                style={{minWidth: 100}}
                columns={actionsColumn}
                className={classNames(className, 'actions-table')}
                rowKey={rowKey || "id"}
                scroll={{x: actionsScrollX, y: actionsScrollY}}
                pagination={false}
            />
        </div>
    )
}