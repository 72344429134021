import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_USERS,
    SET_USERS,
    REQUEST_EDIT_OPERATOR_API_KEY,
    REQUEST_ADD_OPERATOR_API_KEY,
    SET_EDIT_OPERATOR_API_KEY,
    REQUEST_DELETE_OPERATOR_API_KEY,
    SET_DELETE_OPERATOR_API_KEY,
    SET_ADD_OPERATOR_API_KEY,
    REQUEST_OPERATOR_API_KEYS,
    UPDATE_PAGINATION_TOTAL,
    SET_OPERATOR_API_KEYS,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource, DeleteResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

let prevPageApiKeys = 1, prevLimitApiKeys = 10, operatorId = "";

function* fetchOperators() {
    const users =  yield call(GetResource, '/Operators');
    users.sort((a, b) => {
        let aName = a.name.toLowerCase();
        let bName = b.name.toLowerCase();
        if (aName > bName) {
            return 1;
        } else if (aName < bName) {
           return -1;
        } else {
            return 0;
        }
    })
    yield put({type: SET_USERS, users});
}

function* fetchOperatorsApiKeys({data}) {
    data.page && (prevPageApiKeys = data.page)
    data.limit && (prevLimitApiKeys = data.limit)
    data.operatorId && (operatorId = data.operatorId)
    const operatorApiKeys = yield call(GetResource, `/ApiKeys?sortBy=createdate&SortOrder=desc&OperatorId=${operatorId}&page=${prevPageApiKeys}&limit=${prevLimitApiKeys}`);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: operatorApiKeys.totalCount});
    yield put({type: SET_OPERATOR_API_KEYS, operatorApiKeys: operatorApiKeys.data});
}

function* editOperatorApiKey({data}) {
    const res =  yield call(PutResource, `/ApiKeys/${data.id}`, data);
    yield put({type: SET_EDIT_OPERATOR_API_KEY, data: res});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
    yield put({type: REQUEST_OPERATOR_API_KEYS, data: {}});
}


function* addOperatorApiKey({data}) {
    const game =  yield call(PostResource, `/ApiKeys`, data);
    yield put({type: SET_ADD_OPERATOR_API_KEY});
    yield put({type: REQUEST_OPERATOR_API_KEYS, data: {}});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* deleteOperatorApiKey({data}) {
    const res =  yield call(DeleteResource, `/ApiKeys/${data.id}`);
    yield put({type: SET_DELETE_OPERATOR_API_KEY});
    yield put({type: REQUEST_OPERATOR_API_KEYS, data: {}});
}

function* operatorsSaga() {
    yield takeLatest(REQUEST_USERS, safe(onError, fetchOperators));
    yield takeLatest(REQUEST_OPERATOR_API_KEYS, safe(onError, fetchOperatorsApiKeys));
    yield takeLatest(REQUEST_EDIT_OPERATOR_API_KEY, safe(onError, editOperatorApiKey));
    yield takeLatest(REQUEST_ADD_OPERATOR_API_KEY, safe(onError, addOperatorApiKey));
    yield takeLatest(REQUEST_DELETE_OPERATOR_API_KEY, safe(onError, deleteOperatorApiKey));
}

export default operatorsSaga;