import initialStore from "../initialStore";
import { SET_GAMES_SELECT_ALL } from "@actions/actionTypes";

export default function gamesAllReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_GAMES_SELECT_ALL:
            return action.gamesSelectAll;
        default:
            return state;
    }
}