import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    REQUEST_ADD_ASSIGNMENT_GAME,
    REQUEST_EDIT_ASSIGNMENT_GAME
} from "@actions/actionTypes"; 
import OperatorsSelect from "../OperatorsSelect";
import GameSelectAll from "../GameSelectAll";

import {Modal, Form, Button, Input, Space, Switch, Radio, Tabs, Row, Col, Tooltip, Select} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {createGamesAssignment, editGamesAssignment} from "@actions/gamesAssignment";
import Infinite from "react-infinite";
import classname from "classnames";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import CurrencySelect from "@components/CurrencySelect";
import { setErrorMsg } from "@actions/alertAction";

function AddEditGameAssignmentModal({handleCancel, show, intl, gameAssignment, loading, user, createGamesAssignment, editGamesAssignment, setErrorMsg}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [tabMode, setTabMode] = useState("1");
    const [isFirstOpen, setIsFirstOpen] = useState(!gameAssignment?.id);
    const [firstTabError, setFirstTabError] = useState(false);
    const [secondTabValid, setSecondTabValid] = useState(false);

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    const chackIsValid = () => {
        setTimeout(() => {
            let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);


        form.getFieldsError().some((field) => {
            if (field.name.includes("limits")) {
                secondTabValid = field.errors.length > 0
                return field.errors.length > 0
            } else {
                firstTabValid = field.errors.length > 0
                return field.errors.length > 0
            }
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
        }, 100)
    }

    const formChange = () => {
        chackIsValid();
    }

    const enterValidDecimalNumber = (value, name) => {
        const numberRegExp = new RegExp(/^([0-9]+(\.?[0-9]?[0-9]?)?)$/g);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            formValue[name[0]][name[1]][name[2]] = value
            const afterDot = value.split(".")[0]
            const beforeDot = value.split(".")[1]
            if (/\b(0)+[1-9]\d*/g.test(afterDot)) {
                const val = afterDot.replace(/\b(0)+/g, '')
                formValue[name[0]][name[1]][name[2]] = val
            } else {
                if (/\b(0)+[.]\d*/g.test(value)) {
                    const val = afterDot.replace(/\b(0)+/g, '') + "." + beforeDot;
                    formValue[name[0]][name[1]][name[2]] = "0" + val
                }
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    useEffect(() => {
        if (gameAssignment.id) {
            subscribeForError(REQUEST_EDIT_ASSIGNMENT_GAME, () => {
                handleCancel();
            }, gameAssignment.id);
        } else {
            subscribeForError(REQUEST_ADD_ASSIGNMENT_GAME, () => {
                handleCancel();
            });
        }
        
        return () => {
            // clearRoles();
            unSubscribeForError(REQUEST_ADD_ASSIGNMENT_GAME);
            unSubscribeForError(REQUEST_EDIT_ASSIGNMENT_GAME);
        }
    }, [])

    useEffect(() => {
        
        if(gameAssignment.id) {
            let g = Object.assign({}, gameAssignment);
            g = {
                gameId: g.game.id,
                operatorId: g.operator.id,
                bidPercent: g.gameSettings.bidPercent,
                maxBid: g.gameSettings.maxBid,
                minBid: g.gameSettings.minBid,
                showBalance: g.gameSettings.showBalance,
                showChat: g.gameSettings.showChat,
                showCurrencies: g.gameSettings.showCurrencies,
                showName: g.gameSettings.showName,
                showTip: g.gameSettings.showTip,
                limits: g.limits && g.limits.length > 0 && g.limits.map(limit => ({
                        ...limit,
                        nominal: limit.nominal? limit.nominal : []
                    }) || [{
                        currencyId: "",
                        max: "",
                        min: "",
                        nominal: []
                    }]
                
                )
            }
            form.setFieldsValue(g);
        }
    }, [gameAssignment])

    const getRequestBody = (data) => {
        let newData = {
            gameSettings : {
                showName: !!data.showName,
                showBalance: !!data.showBalance,
                showCurrencies: !!data.showCurrencies,
                showChat: !!data.showChat,
                showTip: !!data.showTip,
                minBid: data.minBid || 0,
                maxBid: data.maxBid || 0,
                bidPercent: data.bidPercent || 0,
            },
            limits: data.limits.map(limit => ({
                ...limit,
                nominal: limit.nominal.length? limit.nominal : null
            }))
        }
        if (user?.profile.IsAdmin || user?.profile.IsSuperAdmin) {
            newData.operatorId = data.operatorId
            newData.gameId = data.gameId
        }
        return newData;
    }

    const onFinish = (data) => {
        console.log(data)
        if (!(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)) return;
        data = {...data, minBid: data.minBid === "" ? null :  data.minBid}
        const body = getRequestBody(data);
        if (show === "add") {
            createGamesAssignment(body)
        } else {
            editGamesAssignment({...body, id: gameAssignment.id})
        }
    }

    const changeNominals = (nominal, index) => {
        const minStack = form.getFieldValue(["limits", index, 'min']);
        const maxStack = form.getFieldValue(["limits", index, 'max']);
        if (nominal) {
            let currentValue = nominal[nominal.length - 1];
            if ( typeof currentValue === "string") {
                currentValue = currentValue.replace(",", ".")
                const numberRegExp = new RegExp( /^\d+(\.\d{1,2})?$/)
                if (!numberRegExp.test(currentValue)) {
                    const index = nominal.indexOf(nominal[nominal.length - 1])
                    nominal.splice(index, 1)
                    setErrorMsg(intl.formatMessage({id: `please_input_number`}), true);
                } else if (nominal.indexOf(+currentValue) > -1) {
                    const index = nominal.indexOf(nominal[nominal.length - 1])
                    nominal.splice(index, 1);
                    // setErrorMsg(intl.formatMessage({id: `please_input_number`}), true);
                } else if ((+currentValue < +minStack) || (+currentValue > +maxStack)) {
                    const index = nominal.indexOf(nominal[nominal.length - 1])
                    nominal.splice(index, 1)
                    setErrorMsg(intl.formatMessage({id: `please_check_min_max_nominal_settings`}), true);
                } else {
                    nominal[nominal.length - 1 > 0 ? nominal.length - 1 : 0 ] = +currentValue
                }

                form.setFieldsValue([{nominal: nominal.sort((a, b) => a - b)}])
            }
        }

        stackChange(["limits", index, "nominal"])
    }

    const stackChange = (name) => {
        const value = form.getFieldValue(name)
        if (value.length) {
            form.validateFields([name])
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_game_assignment'/> :
                        <FormattedMessage id='edit_game_assignment'/>}
                    centered
                    className={`formModal games-modal tab-modal`}
                    visible={show}
                    width={tabMode == 1? 596 : 700}
                    onCancel={() => handleCancel()}
                    
                    footer={false}>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode}
                                            className={classname({invalid: firstTabError || secondTabValid})}>
                                <Radio.Button className={classname("centeredBox", {invalid: firstTabError})}
                                                value="1"><FormattedMessage
                                    id={'details'}/></Radio.Button>
                                <Radio.Button className={classname("centeredBox", {invalid: secondTabValid})}
                                                value="2"><FormattedMessage
                                    id={'currency'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                    <Form
                        layout="vertical"
                        form={form}
                        name="game-form"
                        onFinish={onFinish}
                        onValuesChange={formChange}
                        scrollToFirstError
                        onFinishFailed={() => chackIsValid()}
                        validateTrigger="onBlur"
                        initialValues={{
                            limits: [
                                {
                                    currencyId: undefined,
                                    max: "",
                                    min: "",
                                    nominal: []
                                }
                            ]
                        }}
                        >
                            <Tabs activeKey={tabMode} className="overflowContent">
                                <Tabs.TabPane key="1" forceRender={true}>
                                    <Row className="MainModal overflowContent">
                                        <Col span={24} className="MainModal--parts">
                                    {
                                        user?.profile?.IsAdmin || user?.profile.IsSuperAdmin? <div className="top">
                                            <Form.Item name="operatorId"
                                                        label={<FormattedMessage id='operator' />}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: <FormattedMessage id='operator_is_required'/>
                                                            },
                                                        ]}
                                                        onBlur={() => chackIsValid()}>
                                                        <OperatorsSelect />
                                            </Form.Item>

                                            <Form.Item name="gameId"
                                                        label={<FormattedMessage id='game' />}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: <FormattedMessage id='game_is_required'/>
                                                            },
                                                        ]}
                                                        onBlur={() => chackIsValid()}>
                                                        <GameSelectAll />
                                            </Form.Item>
                                            
                                        </div> : "" 
                                    }
                                    <Space direction="horizontal" className="game-form-body">
                                        <div className="left">
                                            <Form.Item name="showName"
                                                        label={<FormattedMessage id='show_name' />}
                                                        valuePropName="checked"
                                                        className="custom-switch">
                                                <Switch disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='show_balance' />}
                                                        valuePropName="checked"
                                                        className="custom-switch"
                                                        name="showBalance">
                                                <Switch disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='show_currencies' />}
                                                        valuePropName="checked"
                                                        className="custom-switch"
                                                        name="showCurrencies">
                                                <Switch disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='show_chat' />}
                                                        valuePropName="checked"
                                                        className="custom-switch"
                                                        name="showChat">
                                                <Switch disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='show_tip' />}
                                                        valuePropName="checked"
                                                        className="custom-switch"
                                                        name="showTip">
                                                <Switch disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                        </div>
                                        <div className="right">
                                            <Form.Item label={<FormattedMessage id='min_bid'/>}
                                                dependencies={['maxBid']}
                                                rules={[
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?\%?$/);
                                                            if (value && !numberRegExp.test(value)) {
                                                                return Promise.reject(intl.formatMessage({id: `min_bid_is_not_valid`}));
                                                            }
                                                            if (Number(getFieldValue("minBid")) > Number(getFieldValue("maxBid"))){
                                                                return Promise.reject(intl.formatMessage({id: `the_min_value_cant_be_larger_the_max`}));
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                                onBlur={() => chackIsValid()}
                                                name="minBid">
                                                <Input autoComplete="off"
                                                        placeholder={intl.formatMessage({id: "type_min_bid"})}
                                                        disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='max_bid'/>}
                                                dependencies={['minBid']}
                                                rules={[
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?\%?$/);
                                                            if (value && !numberRegExp.test(value)) {
                                                                return Promise.reject(intl.formatMessage({id: `max_bid_is_not_valid`}));
                                                            }
                                                            if (Number(getFieldValue("minBid")) > Number(getFieldValue("maxBid"))){
                                                                return Promise.reject(intl.formatMessage({id: `the_max_value_cant_be_smaller_the_min`}));
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                                onBlur={() => chackIsValid()}
                                                name="maxBid">
                                                <Input autoComplete="off"
                                                        placeholder={intl.formatMessage({id: "type_max_bid"})}
                                                        disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                            <Form.Item label={<FormattedMessage id='bid'/>}
                                                rules={[
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?\%?$/);
                                                            if (value && !numberRegExp.test(value) || parseFloat(value) > 100) {
                                                                return Promise.reject(intl.formatMessage({id: `bid_is_not_valid`}));
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                                onBlur={() => chackIsValid()}
                                                name="bidPercent">
                                                <Input autoComplete="off"
                                                        placeholder={intl.formatMessage({id: "type_bid"})}
                                                        disabled={!(user?.profile?.IsAdmin || user?.profile.IsSuperAdmin)}/>
                                            </Form.Item>
                                        </div>
                                    </Space>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="2" forceRender={true}>
                                <Form.List name="limits">
                                    {(fields, {add, remove}) => (
                                        <>
                                        <Infinite containerHeight={400}
                                                    elementHeight={fields.map((_, index) => index === 0 ? 75 : 48)}>
                                            {fields.map(({key, name, fieldKey, ...restField}) =>
                                                <Space key={key} className={`defaultGames ${name === 0 ? "firstChild" : ""}`} size={0}>
                                                    <Form.Item
                                                        label={name === 0 ? <FormattedMessage id='currency_name'/> : undefined }
                                                        {...restField}
                                                        name={[name, 'currencyId']}
                                                        fieldKey={[fieldKey, 'currencyId']}
                                                        onBlur={() => chackIsValid()}
                                                        rules={
                                                            [
                                                                {
                                                                    required: true,
                                                                    message: <FormattedMessage
                                                                        id='currency_is_required'/>
                                                                }
                                                            ]
                                                        }
                                                    >
                                                        <CurrencySelect mode={false} getSelectedValue={() => form.getFieldValue("limits")}/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label={name === 0 ? <FormattedMessage id='min_stake'/> : undefined }
                                                        name={[name, 'min']}
                                                        fieldKey={[fieldKey, 'min']}
                                                        onBlur={() => {
                                                            let max = form.getFieldValue(["limits", name, 'max']);
                                                            let nominal = form.getFieldValue(["limits", name, 'nominal']);
                                                            if (max != "" && max != undefined) {
                                                                form.validateFields([["limits", name, 'max']])
                                                            }

                                                            if (nominal && nominal.length > 0) {
                                                                form.validateFields([["limits", name, 'nominal']])
                                                            }
                                                            
                                                            chackIsValid()
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: <FormattedMessage
                                                                    id='min_stack_required'/>
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {

                                                                    if (value && value <= 0) {
                                                                        return Promise.reject(intl.formatMessage({id: `stake_validation`}));

                                                                    }

                                                                    if (!value || Number(getFieldValue(["limits", name, 'max'])) >= Number(value) || !getFieldValue(["limits", name, 'max'])) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(intl.formatMessage({id: `the_min_stake_value_cant_be_larger_the_max`}));
                                                                },
                                                            })
                                                        ]}
                                                    >
                                                        <Input autoComplete="off"
                                                                onChange={({target}) => enterValidDecimalNumber(target.value, ["limits", name, 'min'])}
                                                                placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                    </Form.Item>
                                                    <div className="separator"/>
                                                    <Form.Item
                                                        label={name === 0 ? <FormattedMessage id='max_stake'/> : undefined}
                                                        {...restField}
                                                        name={[name, 'max']}
                                                        fieldKey={[fieldKey, 'max']}
                                                        onBlur={() => {
                                                            let min = form.getFieldValue(["limits", name, 'min']);
                                                            let nominal = form.getFieldValue(["limits", name, 'nominal']);
                                                            if (min != "" && min != undefined) {
                                                                form.validateFields([["limits", name, 'min']])
                                                            }

                                                            if (nominal && nominal.length > 0) {
                                                                form.validateFields([["limits", name, 'nominal']])
                                                            }

                                                            chackIsValid()
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: <FormattedMessage
                                                                    id='max_stack_required'/>
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                    if (value && value > 2000000000) {
                                                                        return Promise.reject(intl.formatMessage({id: `stake_validation`}));

                                                                    }
                                                                    if (!value || Number(getFieldValue(["limits", name, 'min'])) <= Number(value) || !getFieldValue(["limits", name, 'min'])) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(intl.formatMessage({id: `the_max_stake_value_cant_be_smaller_the_min`}));
                                                                },
                                                            })
                                                        ]}>
                                                        <Input autoComplete="off"
                                                                onChange={({target}) =>  enterValidDecimalNumber(target.value, ["limits", name, 'max'])}
                                                                placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{marginLeft: 24}}
                                                        {...restField}
                                                        label={name === 0 ? <FormattedMessage id='nominal'/> : undefined }
                                                        name={[name, 'nominal']}
                                                        fieldKey={[fieldKey, 'nominal']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: <FormattedMessage id='nominal_is_required'/>
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                    const minStack = getFieldValue(["limits", name, 'min']);
                                                                    const maxStack = getFieldValue(["limits", name, 'max']);

                                                                    if (value && value.length) {
                                                                        const currentValue = value[value.length -1]
                                                                        const numberRegExp = new RegExp( /^\d+(\.\d{1,2})?$/)
                                                                        let stackError =false
                                                                        if (value && value.length > 1 && !numberRegExp.test(currentValue))
                                                                        {
                                                                            return Promise.reject(intl.formatMessage({id: `nominal_is_not_valid`}))
                                                                        }
                                                                        
                                                                        if (value && value.length > 10) {
                                                                            return Promise.reject(intl.formatMessage({id: `nominal_max_count_to_10`}))
                                                                        }

                                                                        value.map(item => {
                                                                            if (item < parseFloat(minStack) || item > parseFloat(maxStack)) stackError = true;
                                                                        })

                                                                        if (stackError) {
                                                                            return Promise.reject(intl.formatMessage({id: `please_check_min_max_nominal_settings`}))
                                                                        }


                                                                        return Promise.resolve();
                                                                    } else {
                                                                        return Promise.reject();
                                                                    }

                                                                },
                                                            })
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder={intl.formatMessage({id: "type_nominal"})}
                                                            mode="tags"
                                                            style={{ width: '100%' }}
                                                            open={false}
                                                            onChange={(value) => changeNominals(value, name)}
                                                        />
                                                    </Form.Item>

                                                    <MinusOutlined  className={classname("iconBox ", {disabled: fields.length === 1})}
                                                                    onClick={() => {
                                                                        if(fields.length !== 1) {
                                                                            remove(name)
                                                                            setTimeout(() =>formChange(), 10)
                                                                        }
                                                                    }}
                                                                    disabled={fields.length === 1}/>
                                                </Space>
                                            )}
                                      
                                        </Infinite>
                                            <Form.Item className="add-button">
                                                <Tooltip placement="left" title={<FormattedMessage id="add_currency"/>}>
                                                    <PlusOutlined
                                                    
                                                        onClick={() => add()}/>
                                                </Tooltip>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Tabs.TabPane>
                        </Tabs>
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: firstTabError || secondTabValid})}
                                    loading={loading._add_game || loading['_edit_game' + gameAssignment.id]}
                                    disabled={!user?.profile?.IsAdmin && !user?.profile.IsSuperAdmin || firstTabError || secondTabValid}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    {/* <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span> */}
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user
    }
}

const mapDispatchToProps = {
    createGamesAssignment,
    editGamesAssignment,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddEditGameAssignmentModal));