import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {getUrlValue, getFormattedUrlObject, getUrlString} from "../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table, Pagination, Typography, Tooltip} from "antd";
import PaginationLoader from "@components/Loaders/paginationLoader";
import HeaderTamplate from "@components/HeaderTamplate";
import {setPagination} from "@actions/paginationsActions";
import {useHistory} from "react-router-dom";
import {
    getOperarorApiKays
} from "@actions/operatorApiKaysActions";
import { CheckOutlined, HighlightOutlined, CopyFilled } from '@ant-design/icons';

const { Paragraph } = Typography;



function OperatorApiKeys({intl, loading, pagination, user, operatorApiKeys, setPagination, getOperarorApiKays}) {

    let history = useHistory()
    const search = history && history.location && history.location.search

    const columnsApiKeys = [
        {
          title: intl.formatMessage({id: "api_key_name"}),
          dataIndex: 'name',
          render: (name, record) => {
            return <Paragraph width="100%" ellipsis={true} copyable={{
                            icon: [<CopyFilled />]
                        }}>
                    {name}
                </Paragraph>
        }
        },
        {
            title: intl.formatMessage({id: "callback_url"}),
            dataIndex: 'callbackUrl',
            render: (callbackUrl) => (
                <Tooltip overlayClassName="not-uppercase"
                    placement="top"
                    title={
                        <Paragraph copyable={{
                                icon: [<CopyFilled />]
                            }}>
                            {callbackUrl}
                        </Paragraph>
                    }>
                    {callbackUrl}
                </Tooltip>
            )
        },
        {
            title: intl.formatMessage({id: "api_key"}),
            dataIndex: 'apiSecret',
            render: (id, record) => {
                return <Paragraph width="100%" ellipsis={true} copyable={{
                            icon: [<CopyFilled />]
                            }}>
                        {id}
                    </Paragraph>
            }
        },
    ];


    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchOperarorApiKeys(true);

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])
 

    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }


    const fetchOperarorApiKeys = (isFirst) => {
        let initialForm = getApiEventsRequestBody()
        initialForm = {
            ...initialForm,
            tab: 1,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            getOperarorApiKays({...initialForm, operatorId: user.profile.sub});
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getApiEventsRequestBody(loc)
                if (initialForm.tab != 1) return;
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: Math.max(10, (+initialForm.limit || pagination.limit)),
                });
                getOperarorApiKays({...initialForm, operatorId: user.profile.sub});
            }
        })
    },[history])

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const initialForm = getApiEventsRequestBody()

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})

    }

    return (
        <>
        <HeaderTamplate className='operator-api-keys-content'
            title="your_operator_id"
            uppendTitle={<Paragraph copyable={{
                            icon: [<CopyFilled />]
                            }}>
                                {user.profile?.sub}
                        </Paragraph>}
            lastUpdate={''}
            size={6}>
            <div className="content-template mainTable">
                
            {
                loading._operator_api_keys === undefined || loading._operator_api_keys ?
                <TableLoader count={13} column={3} actions={false} drag={false} icon={false} pagination={false}/> :
                <Table
                    dataSource={operatorApiKeys}
                    columns={columnsApiKeys}
                    rowKey="id"
                    pagination={false}
                    />
            }

            </div>
        </HeaderTamplate>
        {
            loading._operator_api_keys === undefined || loading._operator_api_keys ?
            <PaginationLoader /> :
            <Pagination defaultCurrent={pagination.page}
                className="ant-table-pagination ant-table-pagination-right"
                total={pagination.total} 
                showSizeChanger={true}
                showLessItems={true}
                loading={loading._operator_api_keys === undefined || loading._operator_api_keys}
                onChange={(page, limit) => handleTablePaginate(page, limit)}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                defaultPageSize={pagination.limit}/>
        }
        </>
    )
}


function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        pagination: state.pagination,
        operatorApiKeys: state.operatorApiKeys
    }
}

const mapDispatchToProps = {
    setPagination,
    getOperarorApiKays
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatorApiKeys));