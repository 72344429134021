import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import {Radio, Tabs} from "antd";
import OperatorApiKeys from "@components/OperatorApiKeys"; 
import ManageOperators from "@components/ManageOperators";
import GameAssignments from "@components/GameAssignments";
import AddEditGameAssignmentModal from "@components/AddEditGameAssignmentModal";
import MyGames from "@components/MyGames";
import classname from "classnames";
import "./style.sass";

function Settings({intl, user}) {
    let history = useHistory()
    const search = history && history.location && history.location.search
    const tabIndex = getUrlValue(search).tab;
    const [tabMode, setTabMode] = useState(tabIndex || "1");
    const [gameAssignmentPopupMode, setGameAssignmentPopupMode] = useState("")
    const [editGameAssignment, setEditGameAssignment] = useState({})

    const handleModeChange = (e) => {
        const mode = e.target.value;
        const {params} = getUrlString({tab: mode})
        history.push({search: params.toString()});
        setTabMode(mode);
    }


    return (
        <div className="content settings-block">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='settings'/>
                </div>
                <div className="tabMode centeredBox custom-tab-header">
                    <Radio.Group onChange={handleModeChange} value={tabMode}>
                        <Radio.Button className={classname("centeredBox")} value="1" style={{minWidth: 230}}>
                            {
                                user?.IsAdmin || user?.IsSuperAdmin? <FormattedMessage id={'manage_operators'}/> : <FormattedMessage id={'api_keys'}/>
                            }
                        </Radio.Button>
                        <Radio.Button className={classname("centeredBox")} value="2" style={{minWidth: 230}}>
                            {
                                user?.IsAdmin || user?.IsSuperAdmin? <FormattedMessage id={'game_assignments'}/> : <FormattedMessage id={'my_games'}/>
                            }
                        </Radio.Button>
                    </Radio.Group>
                </div>
                {
                    (user?.IsAdmin || user?.IsSuperAdmin) && tabMode == 2?
                    <div className="titleArea--BtnGroup" style={{minWidth: 220}}>
                        <button className="mainBtn"
                                style={{border: 'unset'}}
                                onClick={() => { setGameAssignmentPopupMode("add")}}>
                            <div className="icon"/>
                            <FormattedMessage id='add_new_game_assignment'/>
                        </button>
                    </div> : <div style={{minWidth: 220}}></div>
                }
            </div>
            <div className="settings">
                <Tabs activeKey={tabMode} destroyInactiveTabPane={true}>
                    <Tabs.TabPane key="1">
                        {
                            user.IsAdmin || user.IsSuperAdmin? <ManageOperators /> : <OperatorApiKeys />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2">
                        {
                            user.IsAdmin || user.IsSuperAdmin? <GameAssignments onEdit={(gameAssignment) => {
                                setGameAssignmentPopupMode("edit");
                                setEditGameAssignment(gameAssignment);
                            }}/>
                            :
                            <MyGames  onEdit={(gameAssignment) => {
                                setGameAssignmentPopupMode("edit");
                                setEditGameAssignment(gameAssignment);
                            }}/>
                        }
                    </Tabs.TabPane>
                </Tabs>
            </div>
            {
                gameAssignmentPopupMode? <AddEditGameAssignmentModal handleCancel={
                    () => {
                        setEditGameAssignment({})
                        setGameAssignmentPopupMode("")
                    }
                }
                show={gameAssignmentPopupMode}
                gameAssignment={editGameAssignment}/> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        pagination: state.pagination,
        user: state.user?.profile
    }
}

const mapDispatchToProps = {
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));