import initialStore from "../initialStore";
import { SET_GAMES, REQUEST_DELETE_GAME, SET_EDIT_GAME, SET_GAMES_SELECT } from "@actions/actionTypes";

export default function gamesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_GAMES:
            return action.games;
        case SET_GAMES_SELECT:
            return action.gamesSelect;
        case REQUEST_DELETE_GAME:
            return state.filter(game => game.id != action.id);
        case SET_EDIT_GAME:
            return state.map(game => {
                if (game.id == action.data.id) {
                    return action.data;
                }
                return game;
            })
        default:
            return state;
    }
}