import {
    REQUEST_GAMES,
    CLEAR_GAMES,
    REQUEST_ADD_GAME,
    REQUEST_EDIT_GAME,
    REQUEST_DELETE_GAME,
    REQUEST_GAMES_SELECT,
    REQUEST_GAMES_SELECT_ALL
} from "@actions/actionTypes";


export function getGames(data) {
    return dispatch => {
        dispatch({type: REQUEST_GAMES, data});
    }
} 

export function getGamesSelect() {
    return dispatch => {
        dispatch({type: REQUEST_GAMES_SELECT});
    }
}

export function getGamesSelectAll() {
    return dispatch => {
        dispatch({type: REQUEST_GAMES_SELECT_ALL});
    }
}

export function addGame(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_GAME,
            data,
            isOpenedByPopup: true
        });
    }
}


export function editGame(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_GAME,
            data,
            isOpenedByPopup: true
        });
    }
}
export function deleteGame(id) {
    return dispatch => {
        dispatch({type: REQUEST_DELETE_GAME, data: {id}});
    }
}

export function clearGames() {
    return dispatch => {
        dispatch({type: CLEAR_GAMES});
    }
}