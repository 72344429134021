import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_CATEGORIES,
    SET_CATEGORIES,
    REQUEST_ADD_CATEGORY,
    SET_ADD_CATEGORY,
    REQUEST_EDIT_CATEGORY,
    SET_EDIT_CATEGORY,
    REQUEST_DELETE_CATEGORY,
    SET_DELETE_CATEGORY,
    SET_ERROR_MSG, REQUEST_SORT_CATEGORY
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource, DeleteResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* fetchCategories() {
    const categories =  yield call(GetResource, `/Category`);
    yield put({type: SET_CATEGORIES, categories: categories});
}

function* addCategory({data}) {
    const category =  yield call(PostResource, '/Category', data);
    yield put({type: SET_ADD_CATEGORY, category: category});
    yield put({
        type: REQUEST_CATEGORIES
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editCategory({data}) {
    const category =  yield call(PutResource, `/Category/${data.id}`, data);
    yield put({type: SET_EDIT_CATEGORY, data: category});
    yield put({
        type: REQUEST_CATEGORIES
    })
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* deleteCategory({data}) {
    yield call(DeleteResource, `/Category/${data.id}`);
    yield put({type: SET_DELETE_CATEGORY, data});
    yield put({
        type: REQUEST_CATEGORIES
    })
}

function* sortCategory({body}) {
    const categories = yield call(PostResource, `/Category/orders`, body)
    yield put({type: SET_CATEGORIES, categories: categories});
}

function* categoriesSaga() {
    yield takeLatest(REQUEST_CATEGORIES, safe(onError, fetchCategories));
    yield takeLatest(REQUEST_ADD_CATEGORY, safe(onError, addCategory));
    yield takeLatest(REQUEST_EDIT_CATEGORY, safe(onError, editCategory));
    yield takeLatest(REQUEST_DELETE_CATEGORY, safe(onError, deleteCategory));
    yield takeLatest(REQUEST_SORT_CATEGORY, safe(onError, sortCategory));
}

export default categoriesSaga;