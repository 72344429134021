import initialStore from "../initialStore";
import { SET_OPERATOR_API_EVENTS } from "@actions/actionTypes";

export default function operatorApiEventsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_OPERATOR_API_EVENTS:
            return action.data;
        default:
            return state;
    }
}