import Dashboard from "../pages/contents/dashboard";
import Games from "../pages/contents/games";
import Categories from "../pages/contents/categories";
import Settings from "../pages/contents/settings";
import Transactions from "../pages/contents/transactions";
/*import Dealers from "../pages/contents/Dealers";*/
import GamesReport from "../pages/contents/gamesReport";
import PlayersReport from "../pages/contents/playersReport";
import RoundReport from "../pages/contents/roundReport";

export default [
    {
        path: "/dashboard",
        component: Dashboard
    },
    {
        path:'/categories',
        component: Categories
    },
    {
        path:'/games',
        component: Games
    },
    {
        path:'/settings',
        component: Settings
    },
    {
        path:'/transactions',
        component: Transactions
    },
   /* {
        path:'/dealers',
        component: Dealers
    }*/
    {
        path:'/games-report',
        component: GamesReport
    },
    {
        path:'/players-report',
        component: PlayersReport
    },
    {
        path:'/round-report',
        component: RoundReport
    },
]