import React, { useState } from "react";
import { Space, Tooltip, Popover } from "antd";
import UsersList from "../UsersList";
import {
    InfoCircleFilled,
    SettingFilled
} from "@ant-design/icons";
import {FormattedMessage} from "react-intl";
import OperatorDetailsBody from "./OperatorDetailsBody";

function OperatorInfo({onChange, selectedUserId}) {
    const [visibleDetails, setVisibleDetails] = useState(false)
    const [operator, setOperator] = useState();

    const onChangeOperator = (operator) => {
        setOperator(operator);
        onChange(operator);
    }

    const contentConfig = (
        <div>
          <p>{true?<FormattedMessage id="unfreeze"/> : <FormattedMessage id="freeze"/>}</p>
          <p><FormattedMessage id="delete"/></p>
        </div>
    );

    return (
        <Space direction="horizontal" className="operator-info">
            <UsersList selectUser={onChangeOperator} selectedUserId={selectedUserId}/>
            <Space direction="horizontal" className="game-actions" size={[16, 0]}>
                <Popover
                        overlayClassName="operator-details-content"
                        placement="left"
                        content={<OperatorDetailsBody operator={operator} onClose={() => setVisibleDetails(false)}/>}
                        title={<FormattedMessage id="details"/>}
                        trigger="click"
                        visible={visibleDetails}
                        onVisibleChange={setVisibleDetails}
                    >
                    <InfoCircleFilled />
                </Popover>
                {/* <span className="line-vertical"></span>
                <Popover placement="bottomRight" overlayClassName="operator-config-content" content={contentConfig} trigger="click">
                    <SettingFilled />
                </Popover> */}
            </Space> 
        </Space>
    )
}

export default OperatorInfo;