import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ASSIGNMENT_GAMES,
    SET_ASSIGNMENT_GAMES,
    REQUEST_ADD_ASSIGNMENT_GAME,
    SET_ADD_ASSIGNMENT_GAME,
    UPDATE_PAGINATION_TOTAL,
    REQUEST_DELETE_ASSIGNMENT_GAME,
    REQUEST_EDIT_ASSIGNMENT_GAME,
    REQUEST_EDIT_GAME,
    SET_DELETE_ASSIGNMENT_GAME,
    SET_EDIT_ASSIGNMENT_GAME,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource, DeleteResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

let prevPage = "", prevLimit = "", prevOperatorId = "", prevGameName = "";

function* fetchGamesAssignment({data}) {
    data.page && (prevPage = data.page);
    data.limit && (prevLimit = data.limit);
    prevOperatorId = data.operatorId;
    prevGameName = data.gameName;
    const games =  yield call(GetResource, `/GameAssignments?SortBy=Game.Name&page=${prevPage}&limit=${prevLimit}` + (data.operatorId ? `&operatorId=${data.operatorId}` : "") + (data.gameName ? `&SearchQuery=${data.gameName}` : ""));
    yield put({type: UPDATE_PAGINATION_TOTAL, total: games.totalCount});
    yield put({type: SET_ASSIGNMENT_GAMES, games: games.data});
}

function* addGamesAssignment({data}) {
    const games =  yield call(PostResource, '/GameAssignments', data);
    yield put({type: SET_ADD_ASSIGNMENT_GAME, games: games});
    yield put({
        type: REQUEST_ASSIGNMENT_GAMES,
        data: {
            limit: prevLimit,
            page: prevPage,
            gameName: prevGameName,
            operatorId: prevOperatorId
        }
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editGamesAssignment({data}) {
    const game =  yield call(PutResource, `/GameAssignments/${data.id}`, data);
    yield put({type: SET_EDIT_ASSIGNMENT_GAME, data: game});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
    yield put({
        type: REQUEST_ASSIGNMENT_GAMES,
        data: {
            limit: prevLimit,
            page: prevPage,
            gameName: prevGameName,
            operatorId: prevOperatorId
        }
    });
}

function* deleteGamesAssignment({data}) {
    yield call(DeleteResource, `/GameAssignments/${data.id}`);
    yield put({type: SET_DELETE_ASSIGNMENT_GAME, data});
    yield put({
        type: REQUEST_ASSIGNMENT_GAMES,
        data: {
            limit: prevLimit,
            page: prevPage,
            gameName: prevGameName,
            operatorId: prevOperatorId
        }
    })
}

function* gamesAssignmentSaga() {
    yield takeLatest(REQUEST_ASSIGNMENT_GAMES, safe(onError, fetchGamesAssignment));
    yield takeLatest(REQUEST_ADD_ASSIGNMENT_GAME, safe(onError, addGamesAssignment));
    yield takeLatest(REQUEST_DELETE_ASSIGNMENT_GAME, safe(onError, deleteGamesAssignment));
    yield takeLatest(REQUEST_EDIT_ASSIGNMENT_GAME, safe(onError, editGamesAssignment));
}

export default gamesAssignmentSaga;