import {
    REQUEST_CURRENCY,
    CLEAR_CURRENCY,
} from "@actions/actionTypes";


export function getCurrency() {
    return dispatch => {
        dispatch({type: REQUEST_CURRENCY});
    }
}

export function clearCurrency() {
    return dispatch => {
        dispatch({type: CLEAR_CURRENCY});
    }
}