import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import {Space, Form, Button, Input} from "antd";
import classNames from "classnames";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import {getGamesAssignment} from "@actions/gamesAssignment";
import {setPagination} from "@actions/paginationsActions";
import {getUrlValue, getFormattedUrlObject, getUrlString} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import OperatorsSelect from "../OperatorsSelect";
import GameSelectAll from "../GameSelectAll";


function GameAssignmentsFilter({setPagination, loading, intl, getGamesAssignment, user, pagination, games}) {

    let history = useHistory()
    const searchValue = history && history.location && history.location.search

    const [search, setSearch] = useState(getUrlValue(searchValue))
    const [form] = Form.useForm();
    const [selectedOperator, setSelectedOperator] = useState(search.operatorId);

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+search.page || 1)),
            limit: Math.max(10, (+search.limit || 10)),
        })
        fetchOperarorApiEvents(true);
        form.setFieldsValue(search)

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])


    const getApiEventsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || searchValue);
        let initialForm = getFormattedUrlObject(urlObj);
        return {
            ...initialForm
        }
    }


    const fetchOperarorApiEvents = (isFirst) => {
        let initialForm = getApiEventsRequestBody();
        initialForm = {
            ...initialForm,
            tab: 2,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            if (!(user?.IsAdmin || user?.IsSuperAdmin)) initialForm.operatorId = user.sub;
            const {params} = getUrlString(initialForm)
            getGamesAssignment({...initialForm});
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = location && location.search
            let initialForm = getUrlValue(loc);
            if (initialForm.tab != 2) return;
            initialForm = {
                ...initialForm,
                page : Math.max(1, +initialForm.page || pagination.page),
                limit: Math.max(10, +initialForm.limit || pagination.limit)
            }
            setPagination({
                page: initialForm.page,
                limit: initialForm.limit,
            })

            getGamesAssignment({...initialForm});

            resetFormValues();
            form.setFieldsValue({
                ...initialForm
            });
        })
    },[history])

    const resetFormValues = () => {
        form.setFieldsValue({
            operatorId: undefined,
            gameId: undefined
        });
    }

    const finishFilter = (data) => {
        let game = games.find((game) => game.id == data.gameId);
        data = {...data, gameName: game && game.name};
        setSelectedOperator(data.operatorId);
        let queryObj = {...getFormattedBody({...data}), page : 1};

        queryObj = {
            ...queryObj,
            tab: 2,
            page : Math.max(1, +queryObj.page || pagination.page),
            limit: Math.max(10, +queryObj.limit || pagination.limit)
        }

        const {params} = getUrlString(queryObj);
        history.push({search: params.toString()});
    }

    const handleCancel = () => {

    }
    return (
        <Space direction="horizontal" className="operator-duration">
            <Form
                form={form}
                onFinish={finishFilter}
                className="horizontal-form"
                layout="horizontal">
                <div className="wrap-content">
                    <Form.Item label={<FormattedMessage id={"operator_name"}/>}
                                name="operatorId">
                        <OperatorsSelect />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id={"game_name"}/>}
                                name="gameId">
                        <GameSelectAll />
                    </Form.Item>
                </div>
                <Form.Item>
                    <button type="reset" className="miniBtn default" onClick={handleCancel}>
                        <FormattedMessage id='clear'/>
                    </button>
                    <Button type="primary"
                            htmlType="submit"
                            style={{height: 36}}
                            className="miniBtn submit"
                            disabled={loading._games || loading._users}
                            loading={loading._assignment_games}
                    >
                        <FormattedMessage id='search'/>
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user?.profile,
        pagination: state.pagination,
        games: state.games
    }
}

const mapDispatchToProps = {
    getGamesAssignment,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameAssignmentsFilter));